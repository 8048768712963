.section-global-button-dropdown-container{
  position: relative;
  display: inline-block;
}
.section-global-button-dropdown{
  position: relative;
  .section-global-dropdown{
    position: absolute;
    top: 55px;
    right: 0;
    background-color: $light_table_dropdown_color;
    z-index: $z_index_dropdown_primary;
    padding: 2rem 3rem;
    width: 200px;
    height: auto;
    @include projectBoxShadow;
    @include projectBorderRadius;
    ul{
      li{
        @include fontSemiBold;
        padding: .5rem 0;
        cursor: pointer;
        i{
          color: $light_icon_silver;
          margin-right: .5rem;
          display: inline-block;
          width: 15px;
        }
        &:hover{
          color: $light_font_primary_hvr;
        }
      }
    }
    &:after
    {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: $light_table_dropdown_color transparent;
      display: block;
      width: 0;
      z-index: 1;
      top: -10px;
      right: 60px;
    }
  }
}

.branches-card{
  position: relative;
  .section-global-branches-dropdown{
    .section-global-dropdown{
      position: absolute;
      top: 30px;
      right: -20px;
      background-color: $light_table_dropdown_color;
      z-index: $z_index_dropdown_primary;
      padding: 2rem 3rem;
      width: 200px;
      height: auto;
      @include projectBoxShadow;
      @include projectBorderRadius;
      ul{
        li{
          @include fontSemiBold;
          padding: .5rem 0;
          cursor: pointer;
          i{
            color: $light_icon_silver;
            margin-right: .5rem;
            display: inline-block;
            width: 15px;
          }
          &:hover{
            color: $light_font_primary_hvr;
          }
        }
      }
      &:after
      {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: $light_table_dropdown_color transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -10px;
        right: 20px;
      }
    }
  }
}

.global-close-btn{
  position: absolute;
  top: 2rem;
  i{
    font-size: 1.6rem;
    color: $light_icon_silver;
    cursor: pointer;
    @include projectTransition;
    &:hover{
      color: $light_font_primary_hvr;
    }
  }
  &.left-side{
    left: 2rem;
  }
  &.right-side{
    right: 2rem;
  }
}

.global-more-box{
  position: absolute;
  right: 2rem;
  top: 2rem;
  &.table-grid{
    right: 1rem;
    top: 1rem;
  }
  .global-more{
    position: relative;
    z-index: 1;
    .section-global-more-dropdown{
      .global-more-dots{
        i{
          &.icon-more2{
            font-size: 1.8rem;
            color: $light_icon_silver;
            @include projectTransition;
            &.white{
              color: $light_font_white_color;
            }
            &.silver{
              color: $light_icon_silver;
            }
            &:hover{
              color: $light_font_primary_hvr;
            }
          }
        }
      }
      .section-global-dropdown{
        position: absolute;
        top: 30px;
        right: -10px;
        background-color: $light_table_dropdown_color;
        z-index: $z_index_dropdown_primary;
        padding: 2rem 3rem;
        width: 200px;
        height: auto;
        @include projectBoxShadow;
        @include projectBorderRadius;
        ul{
          li{
            @include fontSemiBold;
            padding: .5rem 0;
            cursor: pointer;
            a{
              color: $light_font_primary_color;
              text-align: left;
            }
            i{
              color: $light_icon_silver;
              margin-right: .5rem;
              display: inline-block;
              width: 15px;
            }
            &:hover{
              color: $light_font_primary_hvr;
            }
          }
        }
        &:after
        {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 0 10px 10px;
          border-color: $light_table_dropdown_color transparent;
          display: block;
          width: 0;
          z-index: 1;
          top: -10px;
          right: 10px;
        }
      }
    }
  }
}

.button-dropdown-text{
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}


@media only screen and (max-width: 768px) {
  .section-global-button-dropdown{
    a{
      i{
        margin-right: 0 !important;
      }
    }
    .section-global-dropdown{
      &:after{
        right: 27px;
      }
    }
    button{
      i{
        margin-right: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .section-global-button-dropdown{
    .section-global-dropdown{
      right: 0;
      left: 0;
      width: 100%;
      &:after{
        right: 45%;
      }
    }
  }
}
