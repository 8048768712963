// Transitions
@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  -ms-transition:     $transition;
  transition:         $transition;
}

@mixin border-radius($border-radius...) {
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
}

@mixin animations($animations...) {
  -webkit-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

@mixin box-shadow($box-shadow...) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

@mixin transformRotate($transform){
  -webkit-transform: rotate($transform);
  -moz-transform: rotate($transform);
  -ms-transform: rotate($transform);
  -o-transform: rotate($transform);
  transform: rotate($transform);
}

@mixin transform($transform){
  -webkit-transform: skew($transform);
  -moz-transform: skew($transform);
  -ms-transform: skew($transform);
  -o-transform: skew($transform);
  transform: skew($transform);
}

@mixin transformScale($transform){
  -webkit-transform: scale($transform);
  -moz-transform: scale($transform);
  -ms-transform: scale($transform);
  -o-transform: scale($transform);
  transform: scale($transform);
}

@mixin appearance($val: none) {
  -webkit-appearance: $val;
  -moz-appearance: $val;
  appearance: $val;
}

// Clearfix
@mixin cf {
  clear: both;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin font-swh($size, $weight, $height: unset) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

@mixin fullscreen($position: absolute, $top: inherit, $right: inherit, $left: inherit, $bottom: inherit) {
  position: $position;
  top: $top;
  right: $right;
  left: $left;
  bottom: $bottom;
}
