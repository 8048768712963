#layout {
  &.customTheme {
    ::ng-deep .layout-container-submenu{
      ul{
        li{
          &.active{
            i{
              color: $custom_primary_color_hvr !important;
            }
          }
        }
      }
    }
    ::ng-deep .icon-remove{
      &:hover{
        color: $custom_primary_color_hvr !important;
      }
    }
    ::ng-deep .section-global-dropdown{
      ul{
        li{
          &:hover{
            color: $custom_primary_color_hvr !important;
          }
        }
      }
    }
    ::ng-deep .font_color{
      &_blue{
        color: $custom_primary_color !important;
      }
    }
    ::ng-deep .bg_color{
      &_blue, &_pink{
        background-color: $custom_primary_color !important;
      }
    }
    ::ng-deep button{
      &.form-button{
        &.btn-pink{
          background-color: $custom_primary_color !important;
          &:hover{
            color: $light_font_white_color !important;
            background-color: $custom_primary_color_hvr !important;
          }
        }
      }
    }
    ::ng-deep .input-select-box{
      i{
        color: $custom_primary_color_hvr !important;
      }
    }
    ::ng-deep .input-switch input:checked + .slider {
      background-color: $custom_primary_color_hvr !important;
    }
    ::ng-deep .form-control{
      .input-icon{
        i{
          &:hover{
            color: $custom_primary_color_hvr !important;
          }
        }
      }
    }
    ::ng-deep .label{
      background-color: $custom_primary_color !important;
    }
    ::ng-deep .initials{
      background-color: $custom_primary_color !important;
    }
    ::ng-deep .icon-rounded{
      &.clock{
        background-color: $custom_primary_color !important;
      }
      &.account{
        &:hover{
          background-color: $custom_primary_color_hvr !important;
        }
      }
    }
    ::ng-deep .section-global-branches-dropdown{
      .section-global-dropdown{
        ul{
          li{
            &:hover{
              color: $custom_primary_color_hvr !important;
            }
          }
        }
      }
    }
    ::ng-deep .clickable_link{
      color: $light_font_primary_color !important;
      &:hover{
        color: $custom_primary_color_hvr !important;
      }
    }
    ::ng-deep .spinner{
      &__dot{
        &:before{
          background-color: $custom_primary_color_hvr !important;
        }
      }
    }
    ::ng-deep .colored{
      color: $custom_primary_color_hvr !important;
    }
    ::ng-deep .form-button{
      &.btn-blue{
        background-color: $custom_primary_color !important;
        &:hover{
          background-color: $custom_primary_color_hvr !important;
        }
      }
    }
    ::ng-deep .section-global-button-dropdown{
      .section-global-dropdown{
        ul{
          li{
            &:hover{
              color: $custom_primary_color_hvr !important;
            }
          }
        }
      }
    }
    .layout-nav-breadcrumbs-hamburger{
      i{
        &:hover{
          color: $custom_primary_color_hvr !important;
        }
        &.active{
          color: $custom_primary_color_hvr !important;
        }
      }
    }
    a {
      &:hover {
        color: $custom_primary_color_hvr !important;
      }
    }
    .initials{
      background-color: $custom_primary_color !important;
    }
    i{
      &.icon-email-envelope{
        color: $custom_primary_color !important;
      }
    }
    .layout-menu{
      background-color: $custom_primary_color !important;
      ul{
        li{
          &:hover{
            background-color: $custom_primary_color_hvr !important;
            div{
              i{
                color: $custom_primary_color !important;
              }
            }
          }
          &.active{
            background-color: $custom_primary_color_hvr !important;
            div{
              i{
                color: $custom_primary_color !important;
              }
            }
          }
        }
      }
    }
  }
}
