@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap&subset=latin-ext');

// Fonts
$font-primary: 'Roboto', sans-serif;

html{
  font-family: $font-primary;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @include font-swh(62.5%, 400, 2.4rem);
}

body{
  @include font-swh(1.4rem, 400, 2.4rem);
}

ul,li{
  list-style: none;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  padding: 0;
  margin: 0;
}

a{
  text-decoration: none;
  cursor: pointer;
  @include transition(0.2s ease-in);
  color: $light_font_primary_color;
  &.normalDiv{
    display: block;
    color: initial;
    &:hover{
      color: initial;
    }
  }
  &.normalSpan{
    display: inline-block;
    color: initial;
    &:hover{
      color: initial;
    }
  }
  &:hover{
    color: $light_font_primary_hvr;
  }
}

input, textarea, select, option {
  font-family: $font-primary;
  @include font-swh(1.4rem, 400, 2.4rem);
 }
