@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

// Fonts
$font-primary: 'Roboto', sans-serif;

.ui-tooltip{
  .ui-tooltip-text{
    word-wrap: break-word !important;
  }
}

p-editor{
  ::ng-deep .ui-editor-content{
    background: $light_input_bg !important;
  }
  ::ng-deep .ui-widget-header{
    background: $light_icon_empty !important;
  }
}

.fc-popover{
  .fc-event-container{
    overflow: auto;
    .fc-day-grid-event{
      width: 500px;
    }
  }
}
.fc-header{
  &.ui-widget-header {
    border: 1px solid #3B3C45;
    background: #444754;
    color: white;
    border-radius: 5px 5px 0 0;
  }
}

.fc-body{
  &.ui-widget-content{
  @include border-radius(0 0 5px 5px);
  }
}

.fc-event{
  &.calendar-label-course{
    background-color: #EFA02A !important;
    border-color: #db8d2a !important;
  }
  &.calendar-label-class{
    background-color: #20ADE2 !important;
    border-color: #2099ce !important;
  }
  &.calendar-label-project{
    background-color: #4294E6 !important;
    border-color: #3f83d2 !important;
  }
  &.calendar-label-task{
    background-color: #6F80C7 !important;
    border-color: #5b6cb3 !important;
  }
}

table{
  .fc-event{
    &.calendar-label-course{
      background-color: #EFA02A !important;
      border-color: #db8d2a !important;
    }
    &.calendar-label-class{
      background-color: #20ADE2 !important;
      border-color: #2099ce !important;
    }
    &.calendar-label-project{
      background-color: #4294E6 !important;
      border-color: #3f83d2 !important;
    }
    &.calendar-label-task{
      background-color: #6F80C7 !important;
      border-color: #5b6cb3 !important;
    }
  }
}


#departments{
  .ui-scrollpanel{
    height: calc(100vh - 260px);
    background: transparent;
    border: none;
  }
  .ui-scrollpanel-bar-y {
    width: 2px;
    top: 0;
  }
  .ui-scrollpanel-bar-x {
    height: 4px;
  }
  .ui-scrollpanel-bar {
    background-color: $light_border_primary_color;
  }
}

.ui-calendar{
  width: 100% !important;
}

.ui-widget-header{
  border: 1px solid $light_primary_nav_active;
}

.ui-widget{
  font-family: $font-primary !important;
}

.custom-tree{
  border: none !important;
  width: 100% !important;
  .selected-category{
    color: $light_font_primary_hvr;
    @include fontBold;
  }
  .fa-caret-down, .fa-caret-right{
    color: $light_font_primary_hvr;
  }
  .fa-folder-open, .fa-folder{
    color: $light_font_primary_hvr;
    font-size: 2rem;
  }
}

.ui-datepicker{
  width: 100% !important;
  min-width: 28rem !important;
  @include border-radius(5px);
  .ui-datepicker-title{
    line-height: 2.6em !important;
  }
  &.ui-widget{
    background: $light_primary_nav_active !important;
    .ui-datepicker-header{
      background: $light_font_primary_hvr !important;
      height: 4rem !important;
      line-height: 3rem !important;
      color: $light_font_white_color !important;
      @include fontBold;
      @include border-radius(5px 5px 0 0);
      a{
        &:hover{
          color: $light_font_white_color;
          background: transparent !important;
        }
      }
    }
    .ui-datepicker-calendar{
      thead{
        th{
          background-color: $light_secondary_bg !important;
          background-color: $light_primary_nav_active !important;
          border-bottom: 1px solid $light_primary_menu_bg;
          color: $light_font_white_color;
        }
      }
      td{
        &.ui-datepicker-current-day{
          background-color: $light_font_white_color;
          a{
            background-color: $light_font_primary_hvr !important;
            @include fontSemiBold;
          }
        }
        a{
          background-color: $light_primary_menu_bg !important;
          color: $light_font_white_color !important;
          &:hover{
            background-color: $light_font_primary_hvr !important;
          }
        }
      }
    }
  }
  .ui-datepicker-prev, .ui-datepicker-next{
    width: 2.2em !important;
    height: 2.6em !important;
    span{
      margin-top: -.85em;
    }
  }
}

.ui-shadow{
  @include projectBoxShadow;
}

.fa-angle-left:before{
  font-family: 'icomoon' !important;
  content: "\e903";
  position: relative;
  top: -3px;
}

.fa-angle-right:before{
  font-family: 'icomoon' !important;
  content: "\e904";
  position: relative;
  top: -3px;
}

// CALENDAR
.fc-toolbar{
  h2{
    color: $light_font_primary_color !important;
  }
}
.ui-icon{
  &.ui-icon-seek-prev, &.ui-icon-seek-next{
    display: initial !important;
  }
}

.fc-button-group{
  position: relative;
}

.ui-icon-seek-prev{
  &::before{
    font-size: 1rem;
    position: relative;
    top: 0;
    font-family: 'icomoon' !important;
    content: "\e903" !important;
  }
  &::after{
    font-size: 1rem;
    position: relative;
    top: -1px;
    left: 7px;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    //content: "year" !important;
  }
}

.ui-icon-seek-next{
  &::before{
    font-size: 1rem;
    position: relative;
    top: 0;
    font-family: 'icomoon' !important;
    content: "\e904" !important;
  }
}

//.ui-icon-circle-triangle-w{
//  &::before{
//    font-size: 1rem;
//    position: relative;
//    top: 1px;
//    font-family: 'icomoon' !important;
//    content: "\e903" !important;
//  }
//  &::after{
//    font-size: 1rem;
//    position: relative;
//    width: 80px;
//    top: -1px;
//    left: 8px;
//    display: inline-block;
//    text-transform: uppercase;
//    font-family: Arial, Helvetica, sans-serif;
//    content: "month" !important;
//  }
//}
//
//.ui-icon-circle-triangle-e{
//  &::before{
//    font-size: 1rem;
//    position: relative;
//    top: 1px;
//    font-family: 'icomoon' !important;
//    content: "\e904" !important;
//  }
//}

table{
  .fc-event, .fc-event-dot {
    background-color: $light_font_primary_hvr !important;
  }
  .fc-other-month.fc-future{
    background: $light_secondary_bg !important;
  }
  .ui-widget-header {
    border: none !important;
    border-right: 1px solid $light_border_primary_color;
  }
  .fc-head{
    thead{
      th{
        &.fc-day-header{
          background: $light_primary_menu_bg !important;
          border: none !important;
          border-right: 1px solid $light_border_primary_color !important;
          height: 40px;
          line-height: 40px;
          color: $light_font_white_color;
          @include fontSemiBold;
        }
      }
        th:first-child{
          @include border-radius(5px 0 0 0 !important);
        }
        th:last-child{
          @include border-radius(0 5px 0 0 !important);
        }
      }
  }
  .fc-day{
    &.ui-widget-content{
      &.ui-state-highlight{
        &:hover{
          background-color: $light_border_primary_color !important;
        }
      }
       &:hover{
            background-color: $light_secondary_bg !important;
       }
    }
  }
  .ui-state-highlight {
    color: $light_font_primary_color !important;
    border-color: $light_border_primary_color !important;
    background: $light_border_primary_color !important;
  }
}

// Theme icons from font awesome
.ui-growl{
  top: 120px !important;
  width: auto !important;
  //@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
}

//INPUT CHIPS
.ui-inputtext{
  background-color: $light_btn_white;
  min-height: 3.6rem;
  line-height: 3.6rem;
  border: none;
  padding: 0 .55em !important;
  @include projectBorderRadius;
  &.ui-state-default{
    padding: 0 !important;
    &.input-fix-padding{
      padding: 0 1.5rem !important;
    }
  }
}
.ui-chips-input-token{
  background-color: $light_btn_white;
  height: 3.6rem;
  line-height: 3.6rem;
  padding: 0;
}

.ui-inputtext.ui-state-focus, .ui-inputtext:focus {
  @include box-shadow(none);
}

.ui-state-highlight {
  border-color: $light_font_primary_hvr;
  background: $light_font_primary_hvr;
  color: $light_font_white_color;
}

.ui-chips-token{
  margin: 0;
  padding: 0 1rem;
  //right: 0.5em;
  @include box-shadow(inset 0px 0px 0px 4px rgba(255,255,255,1));

  // TODO: Nemanja Tag Test New Style

  @include box-shadow(none);
  border: 4px solid white;
  height: 4rem;
  line-height: 3rem;
  @include border-radius(10px);
  //margin: 0rem .5rem;

}

.input-colored{
  .ui-chips-input-token{
    width: 100%;
    input{
      background-color: #f4f7fa;
    }
  }
}
.input-colored::placeholder {
  opacity: 30%;
  font-size: 1.2rem;
}

.calendar-input {
  display: block;
  span {
    width: 100%;
    input {
      font-size: 1.4rem;
    }
  }
}

// MULTISELECT
.multiselect{
  div{
   input{
     &.form-control{
       width: 100%;
       height: 3.6rem;
       line-height: 3.6rem;
       border: none;
       padding: 0 2rem;
       @include border-radius(5px);
     }
   }
  }
  .checkbox{
    &.checkbox-success{
     input{
       &.form-control{
         width: inherit;
         height: inherit;
         line-height: inherit;
       }
     }
    }
  }
}

app-multiselect{
  &.input-exam-default{
    input.form-control{
      color: #97A0A8;
      font-size: 1.4rem;
      border-bottom: 1px solid $light_border_primary_color;
      @include border-radius(0 !important);
    }
    &::placeholder{
      font-size: 1.4rem;
    }
  }
  &.input-colored{
    input.form-control{
      background-color: #d6e0ea;
    }
  }
  .dropdown{
    width: 100%;
    top: 32px;
    border-top: 1px solid #e4e7ea;
    @include border-radius(0 0 5px 5px);
    @include projectBoxShadow;
    .options{
      @include border-radius(0 0 5px 5px);
      input[type='checkbox']{
        cursor: pointer;
        position: relative;
        top: 1px;
      }
    }
    input{
      background: #ecf1f7;
      border-left: 1px solid #e4e7ea !important;
      border-right: 1px solid #e4e7ea !important;
      @include border-radius(0 !important);
    }
  }
}

// DIALOG
.ui-confirmdialog {
  border: none;
  @include border-radius(5px !important);
  @include projectBoxShadow;
  .ui-widget-header{
    background: $light_primary_nav_active !important;
    padding: 1rem 2rem !important;
    color: white !important;
    .ui-dialog-titlebar-icon{
      &:hover{
        border-color: transparent !important;
        background: transparent !important;
        color: #212121 !important;
        .fa-close{
          color: $error_color !important;
        }
      }
    }
    .fa-close{
      color: white !important;
      vertical-align: middle;
      position: relative;
      top: 1px;
    }
  }
  .ui-widget-content{
    @include border-radius(0 0 5px 5px);
  }
}

.ui-widget-overlay {
  background: #444754;
  opacity: 0.95;
}

.ui-accordion-header{
  background-color: white !important;
  padding: .5rem 0 !important;
  margin-top: 1rem !important;
  border: none !important;
  @include border-radius(5px !important);
  @include box-shadow(0 2px 4px rgba(0, 0, 0, 0.1) !important);
  &.ui-state-active{
    background-color: #d6e0ea !important;
    @include border-radius(5px 5px 0 0);
  }
}

.ui-accordion-content{
  ul{
    margin: 1rem 0 !important;
  }
}
