@import "colors";

.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  opacity: 0;
  outline: none;
  transition: opacity .3s, visibility .3s;
  visibility: hidden;
  max-width: none !important;
  width: 600px !important;
  z-index: 9999;
  max-height: 700px;
  overflow-y: auto;
}

.shepherd-text h4 {
  color: $shepherd_header_font_color !important;
}

.shepherd-header {
  background: #51A459!important;
}
.shepherd-title {
  color: white !important;
  font-weight: 400 !important;
  font-size: 1.5rem !important;
}

.shepherd-cancel-icon {
  color: #ffffff;
}

