//ELECTRON
$electron_close: #f64747;

//LAYOUT
 $light_primary_bg: #F9F9F9;

//NAVBAR
$light_primary_electron_bg: #FFFFFF;
$light_primary_menu_bg: #57657A;
$light_primary_nav_bg: #FFFFFF;
$light_primary_nav_active: #57657a;

//SIDEBAR
$light_primary_sidebar_bg: #FFFFFF;
$light_primary_sidebar_fixed_bg: #F4F7FA;

$light_secondary_bg: #e9eff5;

//MODALS
$light_primary_modal_bg: #FFFFFF;

//FONTS
$light_font_primary_color: #444754;
$light_font_white_color: #ffffff;
$light_font_grey_color: #b9c3cb;
$light_font_blue_color: #345D9D;


//SHEPARD JS WIZARD FONTS:
$shepherd_header_font_color: #1b4874;

//TABLE
$light_table_row_color: #FFFFFF;

//DROPDOWN
$light_table_dropdown_color: #FFFFFF;

//BORDER
$light_border_primary_color: #D6E0EA;

//BUTTONS
$light_btn_blue: #50A358;
$light_btn_white: #FFFFFF;
$light_btn_primary: #FFFFFF;

//ICONS
$light_icon_blue: #fff;
$light_icon_silver: #B3BDC7;
$light_icon_purple: #6F80C7;
$light_icon_green: #A0D176;
$light_icon_empty: #D6E0EA;
$light_icon_orange: #EFA02A;
$light_icon_lightblue: #1DBFBF;
$light_icon_lightpurple: #ED6C76;


//FORM
$light_form_white_bg: #FFFFFF;

//CARDS
$light_cards_primary_color: #FFFFFF;

//INPUTS
$light_input_bg: #F4F7FA;

//HOVERS
$light_font_primary_hvr: #51A459;
$light_font_secondary_hvr: #E64B8D;
$light_btn_hvr: #E64B8D;

//DISABLED
$light_btn_disabled: #9db8d3;

//ERRORS
$error_color: #e6363f;

//CUSTOM THEME
$custom_primary_color: #051a0e;
$custom_primary_color_hvr: #01a850;

//BUSINESS THEME
$business_primary_color: #3e3c3d;
$business_primary_color_hvr: #07a1d3;

//DARK THEME IS BELOW

////ELECTRON
//$electron_close: #f64747;
//
////LAYOUT
//$light_primary_bg: #3F434A;
//
////NAVBAR
//$light_primary_electron_bg: #262A33;
//$light_primary_menu_bg: #262A33;
//$light_primary_nav_bg: #262A33;
//$light_primary_nav_active: #3f75e5;
//
////SIDEBAR
//$light_primary_sidebar_bg: #262A33;
//$light_primary_sidebar_fixed_bg: #262A33;
//
//$light_secondary_bg: #262A33;
//
////MODALS
//$light_primary_modal_bg: #FFFFFF;
//
////FONTS
//$light_font_primary_color: #96a0ae;
//$light_font_white_color: #ffffff;
//
////TABLE
//$light_table_row_color: #3F434A;
//
////DROPDOWN
//$light_table_dropdown_color: #3F434A;
//
////BORDER
//$light_border_primary_color: #3F434A;
//
////BUTTONS
//$light_btn_blue: #3f75e5;
//$light_btn_white: #3f75e5;
//$light_btn_primary: #3F434A;
//
////ICONS
//$light_icon_blue: $light_primary_bg;
//$light_icon_silver: #B3BDC7;
//$light_icon_purple: #6F80C7;
//$light_icon_green: #A0D176;
//$light_icon_empty: #D6E0EA;
//
////CARDS
//$light_cards_primary_color: #3F434A;
//
////INPUTS
//$light_input_bg: #262A33;
//
////HOVERS
//$light_font_primary_hvr: #3f75e5;
//$light_font_secondary_hvr: #3f75e5;
//$light_btn_hvr: #4992fd;
//
////DISABLED
//$light_btn_disabled: #9db8d3;
//
////ERRORS
//$error_color: #e6363f;
//
////CUSTOM THEME
//$custom_primary_color: #d77064;
//$custom_primary_color_hvr: #6d3636;
//
////BUSINESS THEME
//$business_primary_color: #4c6e7e;
//$business_primary_color_hvr: #678c9c;

// CUSTOM TABLES

$table_td_background_color: #ECF1F7;
$table_subrow_td_background_color: #F8F8FA
