.section-page-with-sidebar-form-container{
  .section-page-with-sidebar-form-container-grid{
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 400px;
    grid-gap: 2rem;
    .section-page-with-sidebar-form-container-left{
      grid-area: left;
    }
    .section-page-with-sidebar-form-container-right{
      grid-area: right;
    }
  }
  .section-page-with-sidebar-form-container-left-full-section{
    width: 100%;
  }
  .section-page-with-sidebar-form-container-left-full-form{
    background-color: $light_form_white_bg;
    padding: 4rem;
    @include projectBorderRadius;
    @include projectBoxShadow;
    &.smaller{
      padding: 2rem 4rem;
    }
  }
  .section-page-with-sidebar-form-container-right-full-form{
    background-color: $light_form_white_bg;
    padding: 4rem;
    @include projectBorderRadius;
    @include projectBoxShadow;
  }
}

//RESPONSIVE
//RESPONSIVE
@media only screen and (max-width: 1740px) {
  .section-page-with-sidebar-form-container{
    .section-page-with-sidebar-form-container-grid{
      grid-template-areas: 'left' 'right';
      grid-template-columns: 1fr;
    }
  }
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 768px) {
}

