.section-documents-form-container{
  .section-documents-form-container-grid{
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 400px;
    grid-gap: 2rem;
    h3{
      margin: 2rem 0;
      @include fontMedium;
      i{
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: 1rem;
      }
    }
    .section-documents-form-container-left{
      grid-area: left;
    }
    .section-documents-form-container-right{
      grid-area: right;
    }
  }
  .section-documents-form-container-left-full-section{
    width: 100%;
  }
  .section-documents-form-container-left-full-form{
    background-color: $light_form_white_bg;
    padding: 4rem;
    @include projectBorderRadius;
    @include projectBoxShadow;
  }
  .section-documents-form-container-left-half-form{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .section-documents-form-container-right-full-form{
    background-color: $light_form_white_bg;
    padding: 4rem;
    @include projectBorderRadius;
    @include projectBoxShadow;
  }
}

#document-table{
  .document-table-container{
    .document-table-filter-row{
      display: grid;
      grid-gap: 0 2rem;
      grid-template-rows: 1fr;
      align-items: center;
      width: 100%;
      background-color: $light_table_row_color;
      height: 3.5rem;
      line-height: 3.5rem;
      margin-bottom: 1rem;
      @include font-swh(1.3rem, 400);
      @include projectBorderRadius;
      @include projectBoxShadow;
      @include cf;
      li{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.first{
          margin-left: 2rem;
        }
        .arrows{
          display: inline-block;
          padding-left: .5rem;
          vertical-align: middle;
          @include projectTransition;
          cursor: pointer;
          .arrow-up{
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $light_icon_silver;
            display: block;
            margin-bottom: .2rem;
          }
          .arrow-down{
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $light_icon_silver;
            display: block;
            margin-top: .2rem;
          }
        }
      }
    }
    .document-table-row{
      display: grid;
      grid-gap: 0 2rem;
      grid-template-rows: 1fr;
      position: relative;
      //align-items: center;
      padding: 1rem 0;
      width: 100%;
      background-color: $light_table_row_color;
      min-height: 4.5rem;
      line-height: 4.5rem;
      margin-bottom: 1rem;
      @include font-swh(1.3rem, 400);
      @include projectBorderRadius;
      @include projectBoxShadow;
      @include cf;
      &.reset-input-width{
            li{
              input[type=search]{
                width: 100%;
              }
            }
        }
      li{
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-right: 1px solid $light_input_bg;
        .listOverflow{
          overflow: hidden;
        }
        &:last-child{
          border-right: none;
        }
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: textfield;
          margin: 0;
          opacity: 0;
        }
        input[type=search]{
          border: none;
          width: 25%;
          &::placeholder{
            color: $light_font_primary_hvr;
          }
        }
        select{
          line-height: 2.6rem;
        }
        .icon-rounded{
          background-color: $light_input_bg;
          display: inline-block;
          cursor: pointer;
          @include projectTransition;
          &:hover{
            background-color: $light_font_secondary_hvr;
            i{
              color: $light_font_white_color;
            }
          }
          i{
            color: $light_font_primary_hvr;
            font-size: 2rem;
            vertical-align: middle;
            position: relative;
            top: -2px;
          }
        }
        .avatar{
          display: inline-block;
          margin-right: 1rem;
        }
        .table-info, .table-more{
          position: relative;
          display: inline-block;
        }
        &.first{
          margin-left: 2rem;
        }
        &.last{
          justify-self: right;
          margin-right: 1rem;
        }
        i{
          &.icon-info{
            margin-right: .5rem;
            color: $light_icon_silver;
            &:hover{
              color: $light_font_primary_hvr;
            }
          }
          &.icon-more2{
            font-size: 2rem;
            color: $light_icon_silver;
            &:hover{
              color: $light_font_primary_hvr;
            }
          }
        }
      }
    }
  }
}

.document-calculation-table{
  .document-calculation-table-grid{
    display: grid;
    justify-items: right;
    .document-calculation-table-grid-inner-grid{
      display: grid;
      grid-template-areas: 'left right';
      background-color: $light_table_row_color;
      border-bottom: 1px solid $light_border_primary_color;
      height: 5rem;
      line-height: 5rem;
      text-align: right;
      font-size: 1.3rem;
      @include projectBoxShadow;
      &.last{
        background-color: $light_font_secondary_hvr;
        color: $light_font_white_color;
        font-size: 1.6rem;
        @include fontBold;
      }
      &:first-child{
        @include border-radius(5px 5px 0 0);
      }
      &:last-child{
        border-bottom: none;
        @include border-radius(0 0 5px 5px);
      }
      .document-calculation-table-grid-inner-left{
        padding: 0 2rem;
        grid-area: left;
        width: 240px;
        border-right: 1px solid $light_border_primary_color;
        input{
          max-width: 100px;
        }
      }
      .document-calculation-table-grid-inner-right{
        padding: 0 2rem;
        grid-area: right;
        width: 120px;
      }
    }
  }
}

//RESPONSIVE

@media only screen and (max-width: 1740px) {
  .section-documents-form-container{
    .section-documents-form-container-grid{
      grid-template-areas: 'left' 'right';
      //grid-template-columns: 1fr;
      grid-template-columns: calc(100vw - 132px);
    }
  }
}

@media only screen and (max-width: 1439px) {
  #document-table{
    padding: 1rem 0 23rem 0;
    margin-bottom: 2rem;
    overflow-x: auto;
    .document-table-container{
      min-width: 1305px;
    }
  }
}


@media only screen and (max-width: 1024px) {
  .form-section-left-first{
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .form-section-left-second{
    .two-columns{
      grid-template-areas: 'left' 'right' !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
  .form-section-left-third{
    .form-section-left-third-first{
      grid-template-areas: 'left' 'right' !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section-documents-form-container{
    .section-documents-form-container-grid{
      grid-template-areas: 'left' 'right';
      grid-template-columns: calc(100vw - 62px);
      &.show{
        grid-template-columns: calc(100vw - 132px);
      }
    }
    .section-documents-form-container-left-half-form{
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
  .form-section-left-first{
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .form-section-left-third{
    .two-columns{
      grid-template-areas: 'left' 'right' !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}
