#lead{
  .lead-header-grid{
    border-top: 1px solid $light_border_primary_color;
    border-bottom: 1px solid $light_border_primary_color;
    padding: 1rem 0;
    display: grid;
    grid-gap: 0 2rem;
    margin: 2rem 0;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    .lead-header-item{
      border-right: 1px solid $light_border_primary_color;
      &:last-child{
        border-right: none;
      }
      .icon-rounded{
        &.first{
          background-color: $light_font_secondary_hvr;
        }
        &.second{
          background-color: $light_icon_green;
        }
      }
    }
  }
  .lead-section-tabs{
    background-color: $light_input_bg;
    margin: 2rem -4rem -4rem -4rem;
    padding: 2rem 4rem;
    @include border-radius(0 0 5px 5px);
    .lead-section-tabs-grid{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      .lead-section-tabs-item{
        background-color: $light_btn_white;
        height: 3.6rem;
        line-height: 3.6rem;
        text-transform: uppercase;
        padding: 0 2rem;
        border-right: 1px solid $light_border_primary_color;
        cursor: pointer;
        @include fontBold;
        .icon-rounded{
          width: 2.4rem;
          height: 2.4rem;
          line-height: 2.4rem;
          i{
            color: $light_font_white_color;
            &.icon-checked{
              font-size: 1.1rem;
              position: relative;
              top: -1px;
            }
            &.icon-remove{
              font-size: 1.7rem;
              position: relative;
              top: 1px;
            }
          }
          &.first{
            background-color: $light_icon_green;
          }
          &.second{
            background-color: $error_color;
          }
        }
        i{
          color: $light_font_primary_hvr;
        }
        &.active{
          background-color: $light_font_primary_hvr;
          color: $light_font_white_color;
          i{
            color: $light_font_white_color;
          }
        }
        &:hover{
          background-color: $light_font_primary_hvr;
          color: $light_font_white_color;
          i{
            color: $light_font_white_color;
          }
        }
        &:first-child{
          border-radius: 3.6rem 0 0 3.6rem;
        }
        &:last-child{
          border-radius: 0 3.6rem 3.6rem 0;
          border-right: none;
        }
        .lead-section-tabs-item-grid{
          display: grid;
          grid-template-columns: 35px 1fr;
          justify-items: center;
          align-items: center;
        }
      }
    }
  }
  .lead-timeline-container{
    .lead-timeline-container-grid{
      display: grid;
      margin: 2rem 0;
      grid-template-columns: 75px 1fr;
      position: relative;
      .icon-rounded{
        position: relative;
        width: 4.4rem;
        height: 4.4rem;
        line-height: 4.4rem;
        i{
          font-size: 2rem;
          vertical-align: middle;
          position: relative;
          top: -4px;
        }
      }
      .timeline-line{
        margin-left: 2.1rem;
        top: 2rem;
        height: 100%;
        position: absolute;
        width: 1px;
        background: $light_border_primary_color;
      }
    }
    .timeline-inner-content-grid{
      display: grid;
      grid-template-rows: 50px 1fr;
      &.single{
        grid-template-rows: auto;
      }
      .timeline-inner-content-header{
        position: relative;
        border-bottom: 1px solid $light_border_primary_color;
        .global-more-box{
          right: 0;
        }
        .label{
          line-height: 2rem;
          margin: 0 1rem;
        }
        .timeline-empty-circle{
          width: 2rem;
          height: 2rem;
          background: $light_input_bg;
          border-radius: 100%;
          border: 1px solid $light_icon_empty;
        }
        .icon-rounded{
          background-color: $light_font_primary_hvr;
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          i{
            font-size: 1rem;
            vertical-align: middle;
            position: relative;
            top: -2px;
          }
        }
      }
      .timeline-inner-content-box{
        margin: 1rem 0;
        .initials{
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          span{
            font-size: 1.1rem;
            position: relative;
            top: -1px;
          }
        }
        ul{
          li {
            color: $light_font_primary_hvr;
            list-style: inside;
            span{
              color: $light_font_primary_color;
            }
          }
        }
        p-lightbox{
          margin: 1rem 0;
          display: block;
          img{
            width: 60px;
            height: 60px;
            @include projectBorderRadius;
          }
          .ui-widget-header {
            border: none;
            @include fontMedium;
            @include border-radius(0 0 5px 5px);
          }
          .ui-lightbox-content{
            img{
              width: initial !important;
              height: initial !important;
              max-width: 768px;
              max-height: 768px;
              @include border-radius(5px 5px 0 0 !important);
            }
          }
          .fa-caret-right{
            display: block;
            width: 20px;
            text-align: center;
            &:before{
              font-family: 'icomoon' !important;
              content: "\e904";
            }
          }
          .fa-caret-left{
            display: block;
            width: 20px;
            text-align: center;
            &:before{
              font-family: 'icomoon' !important;
              content: "\e903";
            }
          }
        }
        .timeline-msg-time{
          color: $light_icon_silver;
        }
      }
    }
  }
  //SIDEBAR
  .lead-sidebar-content{
    i{
      vertical-align: middle;
      margin-right: .3rem;
    }
    .circle-progress-box{
      margin: 2rem 0;
      background-color: $light_input_bg;
      border: 1px solid $light_secondary_bg;
      padding: 1rem;
      @include projectBorderRadius;
    }
  }
  .lead-sidebar-subscriptions{
    i{
      vertical-align: middle;
      margin-right: .3rem;
    }
    .subscriptions_list{
      .initials, .avatar{
        margin: 0 .5rem .5rem 0;
      }
    }
  }
}

//RESPONSIVE

@media only screen and (max-width: 1023px) {
  #lead{
    .lead-section-tabs{
      .lead-section-tabs-grid{
        grid-template-columns: repeat(1, 1fr);
        .lead-section-tabs-item{
          border-right: none;
          border-bottom: 1px solid $light_border_primary_color;
          &:first-child, &:last-child{
            @include border-radius(0 !important);
          }
          &:last-child{
            border-bottom: none;
          }
        }
      }
    }
    .timeline-inner-content-box-header{
      .flex_space_between{
        flex-direction: column-reverse;
      }
    }
  }
}

@media only screen and (max-width: 540px) {

  #lead{
    .lead-timeline-container{
      .lead-timeline-container-grid{
        grid-template-columns: 1fr;
        .lead-timeline-container-grid-left{
          display: none;
        }
      }
    }
  }
}
