@mixin projectBorderRadius() {
  @include border-radius(5px !important);
}

@mixin projectBorderRadiusRound() {
  @include border-radius(100% !important);
}

@mixin projectBorderRadiusHalf() {
  @include border-radius(50% !important)
}

@mixin projectBorderRadiusDownRight() {
  @include border-radius(0 0 0 5px !important);
}

@mixin projectBorderRadiusTopLeftTopRight() {
  @include border-radius(5px 5px 0 0 !important);
}

@mixin projectBorderRadius1-8rem() {
  @include border-radius(1.8rem !important);
}

@mixin projectBorderRadius2-5rem() {
  @include border-radius(2.5rem !important);
}

@mixin projectBoxShadow() {
  @include box-shadow(0 2px 4px rgba(0,0,0,.1) !important);
}

@mixin projectTransition() {
  @include transition(0.2s ease-in !important);
}

@mixin fontThin{
  font-weight: 100 !important;
}

@mixin fontExtraLight{
  font-weight: 200 !important;
}

@mixin fontLight{
  font-weight: 300 !important;
}

@mixin fontRegular{
  font-weight: 400 !important;
}

@mixin fontMedium{
  font-weight: 500 !important;
}

@mixin fontSemiBold{
  font-weight: 600 !important;
}

@mixin fontBold{
  font-weight: 600 !important;
}

@mixin fontExtraBold{
  font-weight: 800 !important;
}

@mixin fontBlack{
  font-weight: 900 !important;
}

@mixin modalAnimationReset{
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

@mixin modalAnimationFromRightOverlay{
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform .7s;
  -moz-transition: transform .7s;
  -ms-transition: transform .7s;
  -o-transition: transform .7s;
  transition: transform .7s;
}

@mixin modalAnimationFromRight{
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform .5s;
  -moz-transition: transform .5s;
  -ms-transition: transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
}

@mixin modalAnimationFromTop{
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: transform .5s;
  -moz-transition: transform .5s;
  -ms-transition: transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
}
