html{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: $light_primary_bg;
  color: $light_font_primary_color;
}

body{
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  background-color: $light_font_white_color;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $light_font_white_color;
  border-radius: 0;
}

*::-webkit-scrollbar-thumb {
  background-color: $light_border_primary_color;
}

*, *:before, *:after{
  box-sizing: border-box;
  margin: 0;
}

*:focus {
  outline: none;
}

img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.colored{
  color: $light_font_primary_hvr !important;
}

.colored_second{
  color: $light_font_secondary_hvr !important;
}

//LAYOUT.COMPONENT
.layout-container-grid{
  display: grid;
  grid-template-areas: 'content';
  grid-template-rows: 1fr;
  min-height: calc(100vh - 50px);
  &.electron{
    min-height: calc(100vh - 75px);
    max-height: calc(100vh - 75px);
    .layout-container-wrapper{
      &.sub-menu{
        max-height: calc(100vh - 126px);
      }
      .layout-container-content{
        .layout-container-content-inner{
          height: calc(100vh - 148px);
          &.normal{
            height: calc(100vh - 97px);
          }
        }
      }
    }
    .layout-container-sidebar{
      max-height: calc(100vh - 75px);
      .layout-container-sidebar-tag{
        .layout-container-sidebar-content{
          .layout-container-sidebar-content-inner{
            height: calc(100vh - 158px) !important;
          }
        }
      }
    }
  }
  &.sub-menu{
    grid-template-areas: 'submenu' 'content';
    grid-template-rows: auto 1fr;
    .layout-container-sidebar{
      display: none;
    }
    &.sidebarStateActive{
      grid-template-areas: 'submenu submenu' 'content sidebar';
      grid-template-rows: auto 1fr;
      //grid-template-columns: 1fr 500px;
      // TODO: Mozilla test
      grid-template-columns: calc(100vw - 570px) 500px;
      .layout-container-sidebar{
        display: block;
      }
    }
  }
  .layout-container-submenu{
    grid-area: submenu;
    position: relative;
    background-color: $light_primary_electron_bg;
    border-top: 1px solid $light_border_primary_color;
    line-height: 5rem;
    .layout-container-submenu-expand-sidebar{
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 50px;
      text-align: center;
      background-color: $light_secondary_bg;
      cursor: pointer;
      @include projectTransition;
      &:hover{
        background-color: $light_font_primary_hvr;
        color: $light_font_white_color;
      }
      i{
        font-size: 1.2rem;
        &.icon-remove{
          vertical-align: middle;
          position: relative;
          top: -1px;
          font-size: 1.8rem;
        }
      }
    }
    .layout-container-submenu-links{
      i{
        color: $light_icon_silver;
      }
    }
    ul{
      display: block;
      @include cf;
      li{
        display: inline-block;
        text-transform: uppercase;
        margin-left: 2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 10%;
        float: left;
        width: auto !important;
        max-width: initial !important;
        // TEST SUBMENU
        @media screen and (min-width: 1280px) {
          width: auto !important;
          max-width: initial !important;
        }
        a{
          &.active{
            color: $light_font_primary_hvr;
            i{
              color: $light_font_primary_hvr;
            }
          }
          span{
            @include font-swh(1.4rem, 600);
            i{
              margin-right: .5rem;
              font-size: 1.8rem;
              vertical-align: middle;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }
  }
  .layout-container-wrapper{
    grid-area: content;
    height: 100%;
    padding: 1rem;
    overflow: auto;
    max-height: calc(100vh - 51px);
    &.sub-menu{
      max-height: calc(100vh - 101px);
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .layout-container-content{
      position: relative;
      height: 100%;
      min-height: max-content;
      background-color: $light_primary_bg;
      //border: 1px solid $light_border_primary_color;
      @include projectBorderRadius;
      .layout-container-content-inner{
        padding: 2rem;
        overflow-y: auto;
        height: calc(100vh - 123px);
        &.normal{
          height: calc(100vh - 73px);
        }
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
  }
  .layout-container-sidebar{
    grid-area: sidebar;
    height: 100%;
    padding: 1rem 1rem 1rem 0;
    //overflow: auto;
    max-height: calc(100vh - 50px);
    .layout-container-sidebar-tag{
      .layout-container-sidebar-content{
        position: relative;
        height: 100%;
        min-height: max-content;
        background-color: $light_primary_sidebar_bg;
        border: 1px solid $light_border_primary_color;
        @include projectBorderRadius;
        .layout-container-sidebar-content-inner{
          border: none !important;
          .ui-scrollpanel-content{
            padding: 2rem 3.8rem 3.8rem 2rem;
          }
          .ui-scrollpanel-bar-y {
            width: 2px;
            top: 0;
          }
          .ui-scrollpanel-bar {
            background-color: $light_border_primary_color;
          }
          .layout-container-sidebar-content-close{
            position: absolute;
            top: 2rem;
            right: 4rem;
          }
          .layout-container-sidebar-content-inner-header{
            padding-bottom: 4rem;
            border-bottom: 1px solid $light_border_primary_color;
            .layout-container-sidebar-content-inner-header-logo{
              margin-top: 1rem;
              .initials{
                width: 10rem;
                height: 10rem;
                line-height: 10rem;
                font-size: 4.5rem;
                margin: 0 auto;
              }
              .avatar{
                text-align: center;
                img{
                  width: 10rem;
                  height: 10rem;
                }
              }
              .icon-rounded{
                margin: auto;
                width: 10rem;
                height: 10rem;
                line-height: 10rem;
                background-color: $light_icon_purple;
                i{
                  line-height: 10rem;
                  font-size: 3rem;
                }
              }
            }
            .layout-container-sidebar-content-inner-header-name{
              margin-top: 2rem;
              text-align: center;
              @include font-swh(2.5rem, 400)
            }
            .layout-container-sidebar-content-inner-header-desc{
              margin-top: 1rem;
              text-align: center;
              @include font-swh(1.5rem, 400)
            }
            .layout-container-sidebar-content-inner-header-mail{
              margin-top: 3rem;
              text-align: center;
              i{
                vertical-align: middle;
                position: relative;
                top: -1px;
                font-size: 1.2rem;
                margin-right: .5rem;
              }
            }
          }
          .layout-container-sidebar-content-inner-grid{
            display: grid;
            grid-template-areas: 'name desc';
            grid-template-columns: 1fr 2fr;
            grid-template-rows: 1fr;
            grid-gap: 4rem;
            padding: 1.3rem 0;
            border-bottom: 1px solid $light_border_primary_color;
            .layout-container-sidebar-content-inner-name{
              grid-area: name;
              justify-self: right;
              text-align: right;
            }
            .layout-container-sidebar-content-inner-desc{
              grid-area: desc;
              justify-self: left;
            }
          }
          .layout-container-sidebar-content-inner-pricelist-grid-name{
            text-align: center;
            text-transform: uppercase;
            padding: 2rem 0;
            @include font-swh(1.8rem, 600)
          }
          .layout-container-sidebar-content-inner-pricelist-grid{
            display: grid;
            grid-template-columns: 4fr repeat(2, 1fr);
            grid-template-areas: 'product price unit';
            grid-template-rows: 1fr;
            border-bottom: 1px solid $light_border_primary_color;
            padding: .5rem 0;
            .layout-container-sidebar-content-inner-pricelist-product{
              grid-area: product;
            }
            .layout-container-sidebar-content-inner-pricelist-unit{
              grid-area: unit;
              justify-self: center;
            }
            .layout-container-sidebar-content-inner-pricelist-price{
              grid-area: price;
              justify-self: right;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1281px) {

}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 768px) {

}

//SIDEBAR MEDIA QUERY
@media only screen and (max-width: 1280px) {
  .layout-container-grid{
    &.sub-menu{
      &.sidebarStateActive{
        grid-template-areas: 'submenu' 'sidebar';
        grid-template-columns: 1fr;
        .layout-container-wrapper{
          display: none;
        }
        .layout-container-sidebar{
          padding: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) {

  .layout-container-grid{
    .layout-container-submenu{
      .layout-container-submenu-showmore{
        display: none;
      }
    }
  }

}

@media only screen and (max-width: 480px) {

  .layout-container-grid{
    .layout-container-submenu{
      .layout-container-submenu-showmore{
        display: block;
      }
      .layout-container-submenu-links{
        display: none;
        &.show{
          display: block;
        }
      }
      ul{
        li{
          width: calc(100% - 4rem) !important;
          max-width: 100% !important;
        }
      }
    }
  }

}
