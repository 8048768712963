.modal-overlay.global_overlay--modal{
  background: rgba(0, 0, 0, 0.65);
  .modal-content{
    position: relative;
    background-color: $light_primary_modal_bg;
    padding: 4rem;
    max-height: 80vh;
    overflow: auto;
    max-width: 1024px;
    height: auto;
    .sub-name{
      font-weight: 600;
      margin-bottom: 1rem;
      display: inline-block;
      width: 100%;
    }
  }
}


.custom-normal-fileupload-button{
  .ui-button{
    font-family: $font-primary;
    border: none;
    height: 3.6rem;
    line-height: 3.6rem;
    text-align: center;
    cursor: pointer;
    font-size: 1.4rem;
    padding: 0 4rem;
    color: $light_font_white_color;
    background-color: $light_btn_hvr;
    @include fontMedium;
    @include box-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
    @include border-radius(2.5rem);
    @include transition(0.2s ease-in);
    .ui-button-text.ui-clickable{
      line-height: 3.6rem;
      padding: 0 !important;
    }
    .ui-button-icon-left.fa.fa-plus{
      margin-left: 1rem;
      &:before{
        font-family: 'icomoon' !important;
        content: "\e979";
      }
    }
    &:not(.ui-state-disabled):hover{
      border: none !important;
      background: $light_btn_white;
      outline: 0 none;
      color: $light_btn_hvr;
    }
  }
}


.page-modal-content-review-content-fileview-img{
  display: inline-block;
  position: relative;
  width: 75px;
  height: 75px;
  margin: .5rem 1rem .5rem 0;
  &:hover{
    .icon-remove{
      display: block;
    }
  }
  .icon-remove{
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: .1rem;
  }
  img{
    width: 75px;
    height: 75px;
    object-fit: contain;
    background: $light_input_bg;
    padding: 1rem;
    @include border-radius(5px);
  }
}

.numeric-text {
  text-align: right;
}

.required {
  color: red;
}

.form-control.ng-invalid.ng-touched:not(form) {
  border: 2px solid #a94442;
}

.form-scrollable-part {
  clear: both;
  padding: 30px;
  height: calc(100vh - 235px);
  overflow: auto;
  overflow-y: unset;
  background-color: #F8F7F7;
  border-radius: 5px;
}

.form-button-wrapper {
  margin:  0px 28px 20px 0px;
  float: right;
  button {
    height: 26px;
    font-size: 12px;
  }
}

.form-button-placeholder {
  height: 46px;
}

.tera-button {
  height: 26px;
  font-size: 12px;
}

.p-tooltip {
  width: max-content;
  max-width: 300px;
}

.p-tooltip-text {
  color: black !important;
  background: white !important;
}

.exportButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  width: 136px;
  height: 40px;
  background-color: #51A459 !important;
  color: #FFFFFF;
  border-radius: 4px !important;
  margin-bottom: 20px;
  float: right;
  margin-top: 15px;

  &:hover {
    background-color: #91CB7B !important;
  }

}

.exportText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  width: 70px;
  height: 18px;
  color: #FFFFFF !important;
  display: grid;
  place-items: center;
  white-space: nowrap;
}

.exportButtonTwo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  width: 136px;
  height: 40px;
  background-color: #51A459 !important;
  color: #FFFFFF;
  border-radius: 4px !important;
  margin-bottom: 20px;
  float: right;
  margin-top: 20px;

  &:hover {
    background-color: #91CB7B !important;
  }
}

.editButtonGlobal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px !important;
  gap: 16px;
  width: 102px !important;
  height: 40px !important;
  background-color: #51A459 !important;
  color: #FFFFFF;
  border-radius: 4px !important;
  float: right !important;

  &:hover {
    background-color: #91CB7B !important;
  }

}

.textEditButtonGlobal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  width: 70px;
  height: 18px;
  color: #FFFFFF;
  display: grid;
  place-items: center;

}

.restoreButton {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  width: 102px;
  height: 40px;
  background-color: #FFFFFF !important;
  border-radius: 4px !important;
}

.textRestoreButton {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  width: 70px;
  height: 18px;
  color: #152235;
}

.borderGlobal {
  border: 1px solid #719BD1;
  margin-left: 5rem;
  margin-right: 5rem;
  background-color: #ffffff;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 1.8rem;
  margin-bottom: 1.2rem;
  overflow-y: auto;
  border-radius: 4px;
}

.form-control {
  margin: 1rem;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #57657A !important;
}

.p-accordion .p-accordion-content {
  border: 1px solid #719BD1 !important;
}

.borderGlobalAccordion {
  border: 1px solid #719BD1;
  margin-left: 5rem;
  margin-right: 5rem;
  background-color: #ffffff;
  margin-top: 1.8rem;
  margin-bottom: 1.2rem;
  overflow-y: auto;
  border-radius: 4px;
  padding: 2rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  // border: 1px solid #719BD1;
}

//input[type=text] {
//  width: unset !important;
//}

.four {
  padding-bottom: 2rem;
}

.study-header-nav {
  background-color: #F8F7F7;
  margin-bottom: 0rem !important;
}

.layout-container-grid .layout-container-wrapper {
  background-color: #F8F7F7;
  padding: unset;
}

.textHeading {
  padding-top: 11rem !important;
}

.dropdown-menu__items {
  border-bottom: 1px solid #152235;
  border-left: 1px solid #152235;
  border-right: 1px solid #152235;
}

.dropdown-menu__button {
  border: 1px solid #152235;
}

.button.form-button.btn-blue {
  background-color: #57657A !important;
}

.text-area-border {
  margin-top: 1rem;
}

.initials {
  background-color: #51A459!important;
}

.descriptionStyle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 1rem;
}

.shepherd-button {
  background: #51A459;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: #FFFFFF!important;
}
