.section-products-form-container{
  &.without-sidebar{
    .section-products-form-container-grid{
      grid-template-areas: 'left';
      grid-template-columns: 1fr;
    }
  }
  .section-products-form-container-grid{
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 400px;
    grid-gap: 2rem;
    h3{
      margin: 2rem 0;
      @include fontMedium;
      i{
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: 1rem;
      }
    }
    .section-products-form-container-left{
      grid-area: left;
    }
    .section-products-form-container-right{
      grid-area: right;
    }
  }
  .section-products-form-container-left-full-section{
    width: 100%;
  }
  .section-products-form-container-left-full-form{
    background-color: $light_form_white_bg;
    padding: 4rem;
    @include projectBorderRadius;
    @include projectBoxShadow;
  }
  .section-products-form-container-left-half-form{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .section-products-form-container-right-full-form{
    background-color: $light_form_white_bg;
    padding: 4rem;
    @include projectBorderRadius;
    @include projectBoxShadow;
  }
  .one-to-three-from-four{
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .product-form-file-upload{
    position: absolute;
    width: 150px;
    height: 150px;
    .ui-fileupload-choose{
      height: 100%;
      width: 100%;
      color: transparent;
      opacity: 0;
      @include projectTransition;
      &:hover{
        opacity: .25;
      }
    }
  }
  .multiple-images{
    //display: grid;
    //grid-template-columns: repeat(8, 1fr);
    //grid-gap: 2rem;
    position: relative;
    display: inline-block;
  }
  .drag-drop-uploaded{
    width: 150px;
    height: 150px;
    position: relative;
    &.multiple{
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
    &:hover{
      .remove-uploaded-img{
        .icon-rounded{
          display: block;
        }
      }
    }
    .choose-default-uploaded-img{
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      .icon-rounded{
        display: none;
        cursor: pointer;
        background-color: $light_icon_empty;
        &.defaultImg{
          display: block;
        }
        &.defaultSelected{
          background-color: $light_font_primary_hvr;
        }
        &:hover{
          background-color: $light_font_primary_hvr;
        }
      }
    }
    .remove-uploaded-img{
      @include fullscreen(absolute, 1rem, 1rem);
      .icon-rounded{
        display: none;
        cursor: pointer;
        background-color: $light_icon_empty;
        &:hover{
          background-color: $light_font_primary_hvr;
        }
      }
    }
    img{
      width: 150px;
      height: 150px;
      border: 1px solid $light_border_primary_color;
      @include border-radius(5px);
    }
  }
  .drag-drop-files{
    display: inline-block;
    width: 150px;
    height: 150px;
    border: 1px solid $light_font_primary_hvr;
    cursor: pointer;
    position: relative;
    @include projectBorderRadius;
    @include projectTransition;
    &.floatedLeft{
      float: left;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
    label{
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      cursor: pointer;
      margin: 0;
    }
    input{
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;
      visibility: hidden;
    }
    .drag-drop-files-inner{
      display: grid;
      width: 100%;
      height: 100%;
      justify-items: center;
      align-items: center;
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      i{
        font-size: 3rem;
      }
    }
  }
}

//RESPONSIVE
@media only screen and (max-width: 1740px) {
  .section-products-form-container{
    .section-products-form-container-grid{
      grid-template-areas: 'left' 'right';
      grid-template-columns: 1fr;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .section-products-form-container{
    .one-to-three-from-four{
      grid-column-start: inherit;
      grid-column-end: inherit;
    }
  }
  .form-section-left-first{
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 768px) {
  .form-section-left-first{
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

