#kanban{
  margin: 1rem 0 -2rem 0;
  display: block;
  //overflow-x: auto;
  .ui-scrollpanel{
    height: calc(100vh - 230px);
    background: transparent;
    border: none;
    //background-color: red;
  }
  .ui-scrollpanel-bar-y {
    width: 2px;
    top: 0;
  }
  .ui-scrollpanel-bar-x {
    height: 4px;
  }
  .ui-scrollpanel-bar {
    background-color: $light_border_primary_color;
  }
}

.kanban-inner{
  width: max-content;
}

.kanban-box{
  width: 300px;
  height: calc(100vh - 235px);
  display: inline-block;
  float: left;
  background-color: $light_input_bg;
  margin-right: 2rem;
  position: relative;
  @include projectBorderRadius;
  &.closed-box{
    width: 50px;
    .kanban-box-inner{
      display: none;
    }
    .kanban-box-header{
      width: 100%;
      height: 100%;
      line-height: initial;
      @include fullscreen(absolute, 0, 0, 0, 0);
      @include projectBorderRadius;
      .kanban-box-header-title{
        position: absolute;
        left: 0;
        top: 2rem;
        right: 0;
        height: auto;
        line-height: 4rem;
        white-space: nowrap;
        overflow: initial;
        @include transformRotate(90deg);
        i{
          &.icon-plus{
          @include transformRotate(0);
          }
        }
      }
    }
  }
  .kanban-box-header{
    background-color: darkmagenta;
    height: 3.5rem;
    line-height: 3.5rem;
    padding: 0 2rem;
    text-transform: uppercase;
    @include border-radius(5px 5px 0 0);
    .kanban-box-header-title{
      color: $light_font_white_color;
      @include fontSemiBold;
      i{
        display: inline-block;
        font-size: 1.4rem;
        vertical-align: middle;
        position: relative;
        top: -1px;
        margin-right: .5rem;
        &.icon-plus{
          @include transformRotate(45deg);
        }
      }
    }
  }
  .kanban-box-inner{
    overflow: auto;
    height: calc(100% - 49px);
    .kanban-box-inner-item{
      margin: 1rem;
      padding: 1rem 1.5rem;
      font-size: 1.2rem;
      background-color: $light_form_white_bg;
      @include projectBorderRadius;
      @include projectBoxShadow;
      .kanban-box-inner-item-progress-bar{
        .kanban-box-inner-item-progress-bar-bar{
          .kanban-box-inner-item-progress-bar-bar-line-box{
            position: relative;
            top: -2px;
            height: 4px;
            background-color: $light_icon_empty;
            display: inline-block;
            width: 200px;
            .kanban-box-inner-item-progress-bar-bar-line-box-inner{
              background-color: $light_font_primary_hvr;
              width: 0;
              height: 4px;
              &.overloaded{
                background-color: $error_color;
              }
            }
          }
        }
      }
      .kanban-box-inner-item-icon-items{
        .icon-rounded{
          display: inline-block;
          background-color: $light_icon_empty;
          width: 2.4rem;
          height: 2.4rem;
          line-height: 2.4rem;
          margin-right: .5rem;
        }
      }
    }
  }
}
