input[type='text'], input[type='email'], select, input[type='date'], input[type='time'], input[type='number'], input[type='password'], textarea {
  outline: none;
  width: 100%;
  border: none;
  height: 3.6rem;
  line-height: 3.6rem;
  padding: .5rem 1.5rem;
  background-color: $light_btn_primary;
  @include projectBorderRadius;
  @include appearance(none !important);
  &.input-search{
    padding: .5rem 1.5rem .5rem 4rem;
    @include projectBorderRadius1-8rem;
    border: 1px solid #D6E0EA;
  }
  &.input-colored{
    background-color: $light_secondary_bg;
  }
  &:disabled{
    cursor: not-allowed;
  }
}

input[type='number'] {
  text-align: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  height: 200px;
  line-height: 160%;
}

app-flatpickr{
  &.input-colored{
    input{
      background-color: $light_secondary_bg;
    }
  }
}

input[type='search']{
  &.input-search-style{
    outline: none;
    width: 100%;
    border: none;
    height: 3.6rem;
    line-height: 3.6rem;
    padding: .5rem 1.5rem;
    background-color: $light_btn_primary;
    @include projectBorderRadius;
    @include appearance(none !important);
  }
  &.input-colored{
    background-color: $light_secondary_bg;
  }
}

select{
  &.select-label{
    height: 2rem;
    line-height: 2rem;
    display: inline-block;
    width: auto;
    padding: 0 2.5rem 0 1rem;
  }
}

.textarea{
  height: auto;
  resize: vertical;
}

.input-select-box{
  position: relative;
  &.with-select-label{
    i{
      line-height: 2.6rem;
      color: $light_font_white_color;
    }
  }
  .input-select{
    background-color: $light_input_bg;
    padding: 0 1.5rem;
    cursor: pointer;
    &.nocolor{
      background-color: $light_btn_white;
    }
  }
  i{
    color: $light_font_primary_hvr;
    pointer-events: none;
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: .6rem;
    line-height: 3.6rem;
    &.icon-calendar{
      font-size: 1.6rem;
    }
  }
}

textarea {
  width: 100%;
}


// CUSTOM CHECKBOX SWITCH

.custom-switchbox{
  div{
    display: inline-block;
    position: relative;
    top: -8px;
    margin-left: 1rem;
    @include fontMedium;
  }
}

.input-switch{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-bottom: 0;
    input{
      display:none;
    }
    input:checked + .slider {
      background-color: $light_font_primary_hvr;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $light_font_primary_hvr;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $light_icon_silver;
      @include transition(.4s);
      &.round{
        @include border-radius(14px);
        &:before{
          @include projectBorderRadiusHalf;
        }
      }
      &:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        @include transition(.4s);
      }
    }
}

// CUSTOM NORMAL CHECKBOX
/* The container */
.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.6rem;
  height: 18px;
  line-height: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px !important;
    width: 18px !important;
    background-color: transparent;
    border: 2px solid $light_border_primary_color;
    @include border-radius(2px);
    &.light-checkmark{
      border: 2px solid #d6e0ea;
      background-color: #ffffff;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkmark {
  border: 2px solid $light_btn_blue;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  background-color: $light_btn_blue;
  border: 2px solid $light_btn_blue;
}


/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
  left: 4px;
  top: 0;
  width: 6px;
  height: 12px;
  border: solid $light_font_white_color;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//FLATPICKER
::ng-deep .flatpickr-calendar{
  background-color: $light_primary_menu_bg !important;
}

// CUSTOM RADIO BUTTON
/* The container */
.input-radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  line-height: 2rem;
  cursor: pointer;
  @include fontSemiBold;
  font-size: 1.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .input-colored{
    background-color: #D6E0EA;
  }

}

/* Hide the browser's default radio button */
.input-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.input-radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $light_btn_white;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.input-radio-container:hover input ~ .input-radio-checkmark {
  background-color: $light_icon_empty;
}

/* When the radio button is checked, add a blue background */
.input-radio-container input:checked ~ .input-radio-checkmark {
  background-color: $light_font_primary_hvr;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.input-radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.input-radio-container input:checked ~ .input-radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.input-radio-container .input-radio-checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $light_btn_white;
}
