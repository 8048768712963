$color-cyan-java: #1DBFBF;
$color-cyan-java: #6f80c7;

.circularProgress {
  box-sizing: content-box;
  position: relative;
  width: 7.4rem;
  height: 7.4rem;
  border-radius: 50%;
}
.circularProgress .circularProgress__overlay {
  position: absolute;
  width: 5.4rem;
  height: 5.4rem;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 1rem;
  margin-top: 1rem;
  text-align: center;
  line-height: 5.8rem;
  font-size: 2.4rem;
  color: $light_icon_empty;
}
.circularProgress .circularProgress.\--0 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(90deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--1 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(93.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--2 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(97.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--3 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(100.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--4 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(104.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--5 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(108deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--6 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(111.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--7 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(115.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--8 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(118.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--9 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(122.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--10 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(126deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--11 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(129.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--12 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(133.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--13 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(136.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--14 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(140.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--15 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(144deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--16 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(147.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--17 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(151.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--18 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(154.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--19 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(158.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--20 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(162deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--21 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(165.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--22 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(169.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--23 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(172.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--24 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(176.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--25 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(180deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--26 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(183.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--27 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(187.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--28 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(190.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--29 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(194.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--30 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(198deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--31 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(201.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--32 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(205.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--33 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(208.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--34 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(212.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--35 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(216deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--36 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(219.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--37 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(223.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--38 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(226.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--39 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(230.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--40 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(234deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--41 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(237.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--42 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(241.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--43 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(244.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--44 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(248.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--45 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(252deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--46 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(255.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--47 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(259.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--48 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(262.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--49 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(266.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--50 {
  background-image: linear-gradient(-90deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--51 {
  background-image: linear-gradient(-86.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--52 {
  background-image: linear-gradient(-82.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--53 {
  background-image: linear-gradient(-79.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--54 {
  background-image: linear-gradient(-75.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--55 {
  background-image: linear-gradient(-72deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--56 {
  background-image: linear-gradient(-68.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--57 {
  background-image: linear-gradient(-64.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--58 {
  background-image: linear-gradient(-61.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--59 {
  background-image: linear-gradient(-57.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--60 {
  background-image: linear-gradient(-54deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--61 {
  background-image: linear-gradient(-50.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--62 {
  background-image: linear-gradient(-46.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--63 {
  background-image: linear-gradient(-43.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--64 {
  background-image: linear-gradient(-39.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--65 {
  background-image: linear-gradient(-36deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--66 {
  background-image: linear-gradient(-32.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--67 {
  background-image: linear-gradient(-28.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--68 {
  background-image: linear-gradient(-25.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--69 {
  background-image: linear-gradient(-21.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--70 {
  background-image: linear-gradient(-18deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--71 {
  background-image: linear-gradient(-14.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--72 {
  background-image: linear-gradient(-10.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--73 {
  background-image: linear-gradient(-7.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--74 {
  background-image: linear-gradient(-3.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--75 {
  background-image: linear-gradient(0deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--76 {
  background-image: linear-gradient(3.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--77 {
  background-image: linear-gradient(7.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--78 {
  background-image: linear-gradient(10.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--79 {
  background-image: linear-gradient(14.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--80 {
  background-image: linear-gradient(18deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--81 {
  background-image: linear-gradient(21.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--82 {
  background-image: linear-gradient(25.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--83 {
  background-image: linear-gradient(28.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--84 {
  background-image: linear-gradient(32.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--85 {
  background-image: linear-gradient(36deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--86 {
  background-image: linear-gradient(39.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--87 {
  background-image: linear-gradient(43.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--88 {
  background-image: linear-gradient(46.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--89 {
  background-image: linear-gradient(50.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--90 {
  background-image: linear-gradient(54deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--91 {
  background-image: linear-gradient(57.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--92 {
  background-image: linear-gradient(61.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--93 {
  background-image: linear-gradient(64.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--94 {
  background-image: linear-gradient(68.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--95 {
  background-image: linear-gradient(72deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--96 {
  background-image: linear-gradient(75.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--97 {
  background-image: linear-gradient(79.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--98 {
  background-image: linear-gradient(82.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--99 {
  background-image: linear-gradient(86.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress .circularProgress.\--100 {
  background-image: linear-gradient(90deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress__range {
  margin-top: 140px;
}
.circularProgress__overlay {
  position: absolute;
  width: 108px;
  height: 108px;
  background-color: #ddd;
  border-radius: 50%;
  margin-left: 6px;
  margin-top: 6px;
  text-align: center;
  line-height: 108px;
  font-size: 24px;
}
.circularProgress.\--0 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(90deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--1 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(93.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--2 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(97.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--3 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(100.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--4 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(104.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--5 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(108deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--6 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(111.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--7 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(115.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--8 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(118.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--9 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(122.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--10 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(126deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--11 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(129.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--12 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(133.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--13 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(136.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--14 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(140.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--15 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(144deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--16 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(147.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--17 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(151.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--18 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(154.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--19 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(158.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--20 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(162deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--21 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(165.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--22 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(169.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--23 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(172.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--24 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(176.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--25 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(180deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--26 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(183.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--27 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(187.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--28 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(190.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--29 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(194.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--30 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(198deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--31 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(201.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--32 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(205.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--33 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(208.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--34 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(212.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--35 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(216deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--36 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(219.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--37 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(223.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--38 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(226.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--39 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(230.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--40 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(234deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--41 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(237.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--42 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(241.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--43 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(244.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--44 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(248.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--45 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(252deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--46 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(255.6deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--47 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(259.2deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--48 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(262.8deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--49 {
  background-image: linear-gradient(90deg, #ECF1F7 50%, transparent 50%, transparent), linear-gradient(266.4deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--50 {
  background-image: linear-gradient(-90deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--51 {
  background-image: linear-gradient(-86.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--52 {
  background-image: linear-gradient(-82.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--53 {
  background-image: linear-gradient(-79.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--54 {
  background-image: linear-gradient(-75.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--55 {
  background-image: linear-gradient(-72deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--56 {
  background-image: linear-gradient(-68.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--57 {
  background-image: linear-gradient(-64.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--58 {
  background-image: linear-gradient(-61.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--59 {
  background-image: linear-gradient(-57.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--60 {
  background-image: linear-gradient(-54deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--61 {
  background-image: linear-gradient(-50.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--62 {
  background-image: linear-gradient(-46.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--63 {
  background-image: linear-gradient(-43.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--64 {
  background-image: linear-gradient(-39.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--65 {
  background-image: linear-gradient(-36deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--66 {
  background-image: linear-gradient(-32.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--67 {
  background-image: linear-gradient(-28.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--68 {
  background-image: linear-gradient(-25.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--69 {
  background-image: linear-gradient(-21.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--70 {
  background-image: linear-gradient(-18deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--71 {
  background-image: linear-gradient(-14.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--72 {
  background-image: linear-gradient(-10.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--73 {
  background-image: linear-gradient(-7.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--74 {
  background-image: linear-gradient(-3.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--75 {
  background-image: linear-gradient(0deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--76 {
  background-image: linear-gradient(3.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--77 {
  background-image: linear-gradient(7.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--78 {
  background-image: linear-gradient(10.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--79 {
  background-image: linear-gradient(14.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--80 {
  background-image: linear-gradient(18deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--81 {
  background-image: linear-gradient(21.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--82 {
  background-image: linear-gradient(25.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--83 {
  background-image: linear-gradient(28.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--84 {
  background-image: linear-gradient(32.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--85 {
  background-image: linear-gradient(36deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--86 {
  background-image: linear-gradient(39.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--87 {
  background-image: linear-gradient(43.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--88 {
  background-image: linear-gradient(46.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--89 {
  background-image: linear-gradient(50.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--90 {
  background-image: linear-gradient(54deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--91 {
  background-image: linear-gradient(57.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--92 {
  background-image: linear-gradient(61.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--93 {
  background-image: linear-gradient(64.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--94 {
  background-image: linear-gradient(68.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--95 {
  background-image: linear-gradient(72deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--96 {
  background-image: linear-gradient(75.6deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--97 {
  background-image: linear-gradient(79.2deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--98 {
  background-image: linear-gradient(82.8deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--99 {
  background-image: linear-gradient(86.4deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
.circularProgress.\--100 {
  background-image: linear-gradient(90deg, $color-cyan-java 50%, transparent 50%, transparent), linear-gradient(270deg, $color-cyan-java 50%, #ECF1F7 50%, #ECF1F7);
}
