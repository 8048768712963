.section-content-header{
  .section-content-header-grid{
    display: grid;
    grid-gap: 2rem;
    grid-template-rows: 1fr;
    padding-bottom: 2rem;
    align-items: center;
    //border-bottom: 1px solid $light_border_primary_color;
    //box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1);
    //margin: 0 5rem;
    grid-template-areas: 'title archive filter search create button';
    .section-content-header-title{
      grid-area: title;
      @include font-swh(1.6rem, 400);
    }
    .section-content-header-archive{
      grid-area: archive;
    }
    .section-content-header-search{
      grid-area: search;
      padding: 0 2rem;
      .section-content-header-search-box{
        position: relative;
        i{
          color: $light_font_primary_hvr;
          position: absolute;
          top: 0;
          left: 1.5rem;
          font-size: 1.6rem;
          line-height: 3.6rem;
        }
      }
    }
    .section-content-header-filter{
      grid-area: filter;
      .section-content-header-filter-box{
        position: relative;
        .section-content-header-filter-dropdown{
          position: absolute;
          width: 350px;
          height: auto;
          padding: 0;
          top: 60px;
          right: -115px;
          background: $light_table_dropdown_color;
          z-index: $z_index_dropdown_primary;
          @include projectBorderRadius;
          @include projectBoxShadow;
          &:after
          {
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 0 15px 15px;
            border-color: $light_table_dropdown_color transparent;
            display: block;
            width: 0;
            z-index: 1;
            top: -15px;
            left: 160px;
          }
          .section-content-header-filter-dropdown-grid{
            display: grid;
            grid-template-areas:
              'select select'
              'clear apply';
            grid-template-rows: auto 50px;
            grid-template-columns: repeat(2, 1fr);
            .section-content-header-filter-dropdown-selecttype{
              grid-area: select;
              padding: 2rem;
              display: flex;
              span{
                width: 20%;
                align-self: center;
              }
              .input-select-box{
                margin-top: .5rem;
                width: 100%;
                align-self: center;
              }
            }
            .section-content-header-filter-dropdown-selecttype-grid{
              width: 100%;
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              app-multiselect{
                input{
                  background-color: $light_input_bg;
                }
              }
            }
            .section-content-header-filter-dropdown-selecttype-grid-container{
              margin: .5rem 0;
            }
            .section-content-header-filter-dropdown-clear{
              grid-area: clear;
              background-color: $light_input_bg;
              text-align: center;
              line-height: 5rem;
              color: $light_font_secondary_hvr;
              cursor: pointer;
              border-right: 1px solid $light_border_primary_color;
              @include border-radius(0 0 0 5px);
            }
            .section-content-header-filter-dropdown-apply{
              grid-area: apply;
              background-color: $light_input_bg;
              text-align: center;
              line-height: 5rem;
              color: $light_font_primary_hvr;
              cursor: pointer;
              @include border-radius(0 0 5px 0);
            }
          }
        }
      }
    }
    .section-content-header-create{
      grid-area: create;
    }

    .section-content-header-button{
      grid-area: button;
    }
    .section-content-header{
      grid-area: archive;
    }
  }
}

//THEMES
.section-content-header{
  &.customTheme{
    .section-content-header-button{
      button{
        &.form-button{
          &.btn-blue{
            background-color: $custom_primary_color !important;
            &:hover{
              background-color: $custom_primary_color_hvr !important;
            }
          }
        }
      }
    }
    .section-content-header-filter-dropdown-clear, .section-content-header-filter-dropdown-apply{
      color: $custom_primary_color !important;
    }
    .input-select-box{
      i{
        color: $custom_primary_color !important;
      }
    }
    button{
      &.form-button{
        &.filter{
          &:hover{
            color: $custom_primary_color_hvr !important;
            @include projectBoxShadow;
          }
        }
      }
    }
    a{
      &:hover{
        color: $custom_primary_color_hvr !important;
      }
    }
    .section-content-header-search-box{
      i{
        color: $custom_primary_color !important;
      }
    }
  }

  &.businessTheme{
    .section-content-header-button{
      button{
        &.form-button{
          &.btn-blue{
            background-color: $business_primary_color !important;
            &:hover{
              background-color: $business_primary_color_hvr !important;
            }
          }
        }
      }
    }
    .section-content-header-filter-dropdown-clear, .section-content-header-filter-dropdown-apply{
      color: $business_primary_color !important;
    }
    .input-select-box{
      i{
        color: $business_primary_color !important;
      }
    }
    button{
      &.form-button{
        &.filter{
          &:hover{
            color: $business_primary_color_hvr !important;
            @include projectBoxShadow;
          }
        }
      }
    }
    a{
      &:hover{
        color: $business_primary_color_hvr !important;
      }
    }
    .section-content-header-search-box{
      i{
        color: $business_primary_color !important;
      }
    }
  }

}
