
label{
  margin-bottom: 1rem;
  display: inline-block;
}
.form-container{

}

.form-title {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.form-group{
  position: relative;
  margin-bottom: 3rem;
  &.last{
    margin-bottom: 0;
  }
  &.flex{
    display: flex;
    &_middle{
      display: flex;
      align-items: center;
      div{
        width: 100%;
      }
    }
  }
}

.form-container-custom-box{
  position: relative;
  background-color: $light_btn_white;
  padding: 2rem;
  border-radius: 5px;
  @include border-radius(5px);
  @include projectBoxShadow;
}

.text-area-border {
  border: 1px solid #719BD1;
}

.text-area-background {
  background-color: #e9eff5;
}

.text-area-background::placeholder {
  opacity: 30%;
  font-size: 1.2rem;
}
.form-control{
  position: relative;
  &.filter{
    background-color: $light_input_bg;
    padding: 0 3rem 0 2rem;
  }
  .input-icon{
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    line-height: 3.6rem;
    .form-control-dropdown-box{
      position: relative;
      .form-info-dropdown{
        position: absolute;
        right: -18px;
        background-color: $light_table_dropdown_color;
        z-index: $z_index_dropdown_primary;
        bottom: 35px;
        width: 240px;
        padding: .5rem 1rem;
        height: auto;
        @include projectBoxShadow;
        @include projectBorderRadius;
        &:after
        {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 10px 10px 0;
          border-color: $light_table_dropdown_color transparent;
          display: block;
          width: 0;
          z-index: 1;
          bottom: -10px;
          right: 15px;
        }
      }
    }
    i{
      vertical-align: middle;
      cursor: pointer;
      @include projectTransition;
      &:hover{
        color: $light_font_primary_hvr;
      }
    }
  }
}

.checkbox-align-center{
  height: 3.6rem;
  line-height: 3.6rem;
  display: flex;
  justify-content: unset;
  align-items: center;
}

a{
  &.form-button{
    background: transparent;
    border: none;
    height: 3.6rem;
    line-height: 3.6rem !important;
    text-align: center;
    cursor: pointer;
    font-family: $font-primary;
    @include font-swh(1.4rem, 500, 2.1rem);
    @include projectTransition;
    &.second{
      margin-left: .5rem;
    }
    &.btn-blue{
      color: $light_font_white_color;
      background-color: $light_btn_blue;
      &.active{
        color: $light_font_white_color;
        background-color: $light_btn_hvr;
      }
    }
    &.btn-pink{
      color: $light_font_white_color;
      background-color: $light_btn_hvr;
      &:hover{
        color: $light_btn_hvr;
        background-color: $light_font_white_color;
      }
    }
    padding: 0 3rem;
    @include projectBorderRadius2-5rem;
    @include projectBoxShadow;
    i{
      &.icon-plus{
        margin-right: .5rem;
        font-size: 1.2rem;
      }
    }
    &:hover{
      background-color: $light_btn_hvr;
    }
    &:disabled{
      cursor: not-allowed;
      background-color: $light_btn_disabled;
    }
    &.filter{
      background-color: $light_btn_white;
      @include fontRegular;
      @include box-shadow(none !important);
      &:hover{
        color: $light_font_primary_hvr;
        @include projectBoxShadow;
      }
      i{
        margin-right: .5rem;
        vertical-align: middle;
        &.icon-plus{
          font-size: 1.2rem;
        }
      }
    }
  }
}


button{
  background: transparent;
  border: none;
  height: 3.6rem;
  line-height: 3.6rem;
  text-align: center;
  cursor: pointer;
  font-family: $font-primary;
  @include font-swh(1.4rem, 500, 2.1rem);
  @include projectTransition;
  &.btn-table{
    color: $light_font_primary_color;
  }
  &.form-button{
    height: 40px;
    &.second{
      margin-left: .5rem;
    }
    &.btn-blue{
      color: $light_font_white_color;
      background-color: #57657A;
      &:hover {
        background-color: #91CB7B;
      }
      &.clicked {
        background-color: #D0E7C7;
      }
      &[disabled] {
        background-color: #B7B7B7;
      }
    }
    &.btn-green {
      color: $light_font_white_color;
      background-color: #51A459;

      &:hover {
        background-color: #91CB7B;
      }
      &.clicked {
        background-color: #D0E7C7;
      }
      &[disabled] {
        background-color: #B7B7B7;
      }
    }
    &.btn-blue--light{
      color: $light_font_white_color;
      background-color: #345D9D;
      &:hover {
        background-color: #8F91A2;
      }
    }
    &.btn-pink{
      color: $light_font_blue_color;
      background-color: $light_primary_electron_bg;
      border: 1px solid $light_font_blue_color;
      &:hover{
       opacity: 0.5;
        background-color: $light_font_white_color;
      }
    }
    &.btn-table{
      color: $light_font_primary_color;
    }
    &.btn-darkblue{
      color: #152235;
      background-color: $light_primary_electron_bg;
      border: 1px solid $light_font_blue_color;
      &:hover{
        background-color: #E6E8EA;
        color:  #95ADD9;
      }
    }
    padding: 0 3rem;
    @include projectBorderRadius;
    @include projectBoxShadow;
    @media only screen and (max-width: 768px) {
      padding: 0 2rem;
    }
    i{
      &.icon-plus{
        margin-right: .5rem;
        font-size: 1.2rem;
      }
    }
    &:hover{
      background-color: $light_btn_hvr;
    }
    &:disabled{
      cursor: not-allowed;
      background-color: $light_btn_disabled;
    }
    &.filter{
      color: $light_icon_silver;
      background-color: $light_btn_white;
      @include fontRegular;
      @include box-shadow(none !important);
      &:hover{
        color: $light_font_primary_hvr;
        @include projectBoxShadow;
      }
      i{
        margin-right: .5rem;
        vertical-align: middle;
        &.icon-plus{
          font-size: 1.2rem;
        }
      }
    }
    &.archive{
      color: $light_icon_silver;
      background-color: $light_btn_white;
      @include fontRegular;
      @include box-shadow(none !important);
      &:hover{
        color: $light_font_primary_hvr;
        @include projectBoxShadow;
      }
      i{
        vertical-align: middle;
        &.icon-plus{
          font-size: 1.2rem;
        }
      }
    }
  }
}

.form-name{
  @include font-swh(1.6rem, 500)
}

.form-grid-title{
  padding: 2rem 0;
  margin: -3rem 0 2rem 0;
  @include font-swh(1.6rem, 500);
  border-bottom: 1px solid $light_border_primary_color;
}

.form-flex{
  display: flex;
  align-items: center;
}

.form-grid{
  display: grid;
  grid-gap: 2rem;
  position: relative;
  &.form_grid--normal{
    display: block;
  }
  label{
    @include fontSemiBold;
  }
  .required{
    color: $error_color;
  }
  .form-grid-three-col{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 2rem;
  }
  &.full{
    grid-template-areas: 'full';
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    &.reset-top{
      margin-top: -2rem;
    }
    &_fixed_height{
      grid-template-areas: 'full';
      grid-template-columns: 1fr;
      height: 40px;
      line-height: 40px;
    }
    .form-grid-full{
      grid-area: full;
    }
  }
  &.two-columns{
    grid-template-areas: 'left right';
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
    .form-grid-two-columns-left{
      grid-area: left;
    }
    .form-grid-two-columns-right{
      grid-area: right;
    }
  }
  &.three-columns{
    grid-template-areas: 'left middle right';
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 2rem;
    .form-grid-three-columns-left{
      grid-area: left;
      position: relative;
    }
    .form-grid-three-columns-middle{
      grid-area: midle;
    }
    .form-grid-three-columns-right{
      grid-area: right;
      position: relative;
    }
  }
}

@media only screen and (max-width: 640px) {
  .form-grid{
    &.two-columns{
      grid-template-areas: 'left' 'right';
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
