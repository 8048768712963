/* You can add global styles to this file, and also import other style files */
@import "assets/font-awesome-4.7.0/css/font-awesome.min.css";
@import "assets/styles/mixins";
//PROJECT MIXINS
@import "assets/styles/themes/projectMixins";
@import "assets/styles/colors";
@import "assets/styles/variables";
@import "assets/styles/primeng/primengTheme";
@import "assets/styles/typography";
@import "assets/styles/flatpickr";
@import "assets/styles/layout";
@import "assets/styles/helpers";
@import "assets/styles/iconmoon";
@import "assets/styles/icons";

@import "assets/styles/sections";

@import "assets/styles/dropdowns";

@import "assets/styles/modals";

@import "assets/styles/forms";
@import "assets/styles/inputs";

@import "assets/styles/tables";
@import "assets/styles/kanban";

@import "assets/styles/circleProgress";

@import "assets/styles/responsive";

//PAGES
@import "assets/styles/pages/documents/forms";
@import "assets/styles/pages/products/forms";
@import "assets/styles/pages/lead/landing";
@import "assets/styles/pages/with-sidebar/landing";

//THEMES
@import "assets/styles/themes/customTheme";
@import "assets/styles/themes/businessTheme";


// FURIOUS FRAMEWORK
@import "assets/styles/furious/grid";

// GLOBAL
@import "assets/styles/global";

// SHEPHERD.JS WIZARD STYLES OVERRIDE
@import "assets/styles/sherpherdJsOverride";

//@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

