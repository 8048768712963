.font{
  &_thin{
    font-weight: 100;
  }
  &_extra-light{
    font-weight: 200;
  }
  &_light{
    font-weight: 300;
  }
  &_regular{
    font-weight: 400;
  }
  &_medium{
    font-weight: 500;
  }
  &_semi-bold{
    font-weight: 600;
  }
  &_bold{
    font-weight: 600;
  }
  &_extra-bold{
    font-weight: 800;
  }
  &_black{
    font-weight: 800;
  }
}

.text-align{
  &_right{
    text-align: right;
  }
  &_left{
    text-align: left;
  }
  &_center{
    text-align: center;
  }
}

.line-height{
  &_reset{
    line-height: inherit !important;
  }
}

.justify{
  &_right{
    justify-self: right;
  }
}

.margin{
  &_top{
    margin-top: 4rem;
  }
}

.clickable{
  cursor: pointer;
  &_link{
    cursor: pointer;
    @include projectTransition;
    &_second{
      cursor: pointer;
      @include projectTransition;
      &:hover{
        color: $light_font_secondary_hvr !important;
      }
    }
    &_third{
      cursor: pointer;
      @include projectTransition;
      &:hover{
        color: $light_icon_silver !important;
      }
    }
    &:hover{
      color: $light_font_primary_hvr !important;
    }
  }
  &_link_icon{
    cursor: pointer;
    color: $light_icon_silver;
    @include projectTransition;
    font-size: 1.6rem;
    vertical-align: middle;
    position: relative;
    top: -1px;
    &:hover{
      color: $light_font_primary_hvr !important;
    }
  }
}

.font_color{
  &_blue{
    color: $light_font_primary_hvr !important;
  }
}

.bg_color{
  &_blue{
    background-color: $light_font_primary_hvr !important;
  }
  &_pink{
    background-color: $light_font_secondary_hvr !important;
  }
}

.capitalize{
  text-transform: capitalize;
}

.work-break{
  word-break: break-all;
}

.txtOver {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.global-hidden{
  display: none !important;
}

.label-flex{
  display: flex;
  justify-content: space-between;
}

.flex{
  &_space_between{
    display: flex;
    justify-content: space-between;
  }
}

.margin{
  &_l{
    &_1{
      margin-left: 1rem !important;
    }
    &_2{
      margin-left: 2rem !important;
    }
  }
  &_r{
    &_0{
      margin-right: 0rem !important;
    }
    &_1{
      margin-right: 1rem !important;
    }
    &_2{
      margin-right: 2rem !important;
    }
  }
  &_b{
    &_0{
      margin-bottom: 0rem !important;
    }
    &_1{
      margin-bottom: 1rem !important;
    }
    &_2{
      margin-bottom: 2rem !important;
    }
  }
  &_t{
    &_1{
      margin-top: 1rem !important;
    }
    &_2{
      margin-top: 2rem !important;
    }
    &_3{
      margin-top: 3rem !important;
    }
    &_4{
      margin-top: 4rem !important;
    }
  }
}

.font_style_poppins{
  font-family: $font-primary !important;
}

.normal-separator{
  width: 100%;
  height: 1px;
  padding: 0 !important;
  margin: 2rem 0;
  background-color: $light_border_primary_color;
}

.list-separator{
  width: 100%;
  height: 1px;
  padding: 0 !important;
  margin: 1rem 0;
  background-color: $light_border_primary_color;
}

.changeZIndex{
  z-index: $z_index_electron_menu !important;
}

//FLEX HELPERS
.flex_space_between{
  justify-content: space-between;
}

.flex_align_items_middle{
  align-items: center;
}

//DISPLAY HELPERS
.display_inline_block{
  display: inline-block;
}

.display_block{
  display: block;
}

// Active class
.helper-active{
  color: $light_font_primary_hvr !important;;
}

.global-error-message {
  color: $error_color;
  font-size: 1.2rem;
  //position: absolute;
  //margin-top: .3rem;
}

.global-error-message-floating {
  color: $error_color;
  font-size: 1.2rem;
  position: absolute;
}
