.small_table {
  max-width: 60%;
  margin: 0 auto;
  thead {
    tr {
      th:first-child {
        width: 100px;
      }
    }
  }
}

#table{
  margin: 2rem 0;
  margin: 1rem 0 -2rem 0;
  display: block;
  overflow-x: auto;
  .ui-scrollpanel{
    height: calc(100vh - 320px);
    background: transparent;
    border: none;
  }
  .ui-scrollpanel-bar-y {
    width: 2px;
    top: 0;
  }
  .ui-scrollpanel-bar-x {
    height: 4px;
  }
  .ui-scrollpanel-bar {
    background-color: $light_border_primary_color;
  }
  &.mailbox{
    margin: 0;
    height: calc(100vh - 140px);
    //&::-webkit-scrollbar {
    //  width: 2px;
    //  height: 10px;
    //  background-color: $light_font_white_color;
    //}
    &.electron{
      height: calc(100vh - 165px);
    }
    .table-container{
      margin: 0;
      .table-row{
        background-color: $light_input_bg;
        margin: 0;
        border-bottom: 1px solid $light_secondary_bg;
        height: 5.5rem;
        line-height: 5.5rem;
        @include border-radius(0 !important);
        @include box-shadow(none !important);
        &:hover{
          background-color: $light_input_bg !important;
          @include box-shadow(inset 3px 0px 0px 0px $light_font_primary_hvr !important);
        }
        &.not-seen{
          background-color: $light_table_row_color;
          li{
            &:nth-child(4), &:nth-child(5){
              @include fontBold;
            }
          }
        }
        li{
          &.first{
            margin-left: 2rem;
          }
          &.last{
            margin-right: 2rem;
          }
          &.mailbox-icon{
            i{
              vertical-align: middle;
              position: relative;
              top: -1px;
              &.icon-email-envelope{
                font-size: 1.6rem;
              }
              &.icon-drafts{
                color: $light_icon_empty;
                font-size: 2rem;
              }
            }
          }
          .mailbox-notification{
            margin-left: .5rem;
            background-color: $light_secondary_bg;
            padding: 0 1rem;
            font-size: 1.2rem;
            @include fontMedium;
            color: $light_font_primary_hvr;
            @include border-radius(10px);
          }
        }
      }
    }
  }
  .no-overflow{
    overflow: inherit !important;
  }
  .table-container{
    width: 100%;
    margin: 1rem 0 4rem 0;
    @media only screen and (min-width: 1920px) {
      min-width: 100%;
    }
    .colored{
      color: $light_font_primary_hvr;
    }
    .table-filter-row{
      display: grid;
      grid-gap: 0 2rem;
      grid-template-rows: 1fr;
      align-items: center;
      width: 100%;
      width: 99%;
      background-color: $light_table_row_color;
      height: 3.5rem;
      line-height: 3.5rem;
      margin-bottom: 1rem;
      @include font-swh(1.3rem, 400);
      @include projectBorderRadius;
      @include projectBoxShadow;
      @include cf;
      @media only screen and (min-width: 1920px) {
        width: 98%;
      }
      li{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.first{
          margin-left: 1rem;
          &.checkbox{
            margin-left: 0;
            position: relative;
            left: 1rem;
          }
        }
        &.last{
          justify-self: right;
          margin-right: 1rem;
        }
        i{
          &.icon-more2{
            font-size: 2rem;
            color: $light_icon_silver;
            &:hover{
              color: $light_font_primary_hvr;
            }
          }
        }
        .table-more{
          position: relative;
          display: inline-block;
        }
        .arrows{
          display: inline-block;
          padding-left: .5rem;
          vertical-align: middle;
          @include projectTransition;
          cursor: pointer;
          .arrow-up{
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $light_icon_silver;
            display: block;
            margin-bottom: .2rem;
          }
          .arrow-down{
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $light_icon_silver;
            display: block;
            margin-top: .2rem;
          }
        }
      }
    }
    .table-row{
      display: grid;
      grid-gap: 0 2rem;
      grid-template-rows: 1fr;
      position: relative;
      align-items: center;
      width: 100%;
      width: 99%;
      background-color: $light_table_row_color;
      height: 4.5rem;
      line-height: 4.5rem;
      margin-bottom: 1rem;
      @include font-swh(1.3rem, 400);
      @include projectBorderRadius;
      @include projectBoxShadow;
      @include cf;
      @media only screen and (min-width: 1920px) {
        width: 98%;
      }
      li{
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .table-info, .table-more{
          position: relative;
          display: inline-block;
        }
        &.first{
          margin-left: 1rem;
          &.checkbox{
            margin-left: 0;
            position: relative;
            left: 1rem;
          }
        }
        &.last{
          justify-self: right;
          margin-right: 1rem;
        }
        i{
          &.icon-info{
            margin-right: .5rem;
            color: $light_icon_silver;
            &:hover{
              color: $light_font_primary_hvr;
            }
          }
          &.icon-more2{
            font-size: 2rem;
            color: $light_icon_silver;
            &:hover{
              color: $light_font_primary_hvr;
            }
          }
        }
      }
    }
  }
}

//TABLE DROPDOWN
#table{
  .table-info-dropdown{
    max-width: 1200px;
    overflow: hidden;
    position: absolute;
    right: -13px;
    background-color: $light_table_dropdown_color;
    z-index: $z_index_dropdown_primary;
    bottom: 35px;
    width: auto;
    padding: .5rem 1rem;
    height: auto;
    @include projectBoxShadow;
    @include projectBorderRadius;
    &:after
    {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 0;
      border-color: $light_table_dropdown_color transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -10px;
      right: 15px;
    }
  }
  .table-more-dropdown{
    position: absolute;
    //bottom: -32px;
    top: -60px;
    right: 35px;
    background-color: $light_table_dropdown_color;
    z-index: $z_index_dropdown_primary;
    padding: 2rem 3rem;
    width: auto;
    height: auto;
    @include projectBoxShadow;
    @include projectBorderRadius;
    &.mailbox-dropdown{
      bottom: inherit;
      top: 5px;
      padding: 1rem 3rem 1rem 3rem;
      &:after{
        bottom: inherit;
        top: 13px;
      }
      ul{
        li{
          &:nth-child(4), &:nth-child(5){
            @include fontMedium;
          }
          height: 30px;
          line-height: 30px;
          &.separator{
            height: 1px;
            background-color: $light_border_primary_color;
            padding: 0;
            margin: .5rem -3rem 1rem -3rem;
          }
        }
      }
    }
    ul{
      li{
        @include fontSemiBold;
        padding: .2rem 0;
        i{
          color: $light_icon_silver;
          margin-right: .5rem;
          display: inline-block;
          width: 15px;
        }
        &:hover{
          color: $light_font_primary_hvr;
        }
      }
    }
    &:after
    {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent $light_table_dropdown_color;
      display: block;
      width: 0;
      z-index: 1;
      right: -10px;
      //bottom: 34px;
      top: 61px;
    }
  }
  .activity-list{
    margin: 1rem 0 2rem 0;
    //FOR NOW REMOVE SCROLLABLE ON ACTIVITY LIST
    //min-width: 1440px;
    max-width: 98.5%;
    @media only screen and (max-width: 1589px) {
      min-width: 1440px;
    }
    .activity-list-grid{
      display: grid;
      background-color: $light_table_row_color;
      border-bottom: 1px solid $light_border_primary_color;
      min-height: 4.5rem;
      line-height: 4.5rem;
      grid-gap: 0 2rem;
      padding: 0 2rem;
      align-items: center;
      @include projectBoxShadow;
      .avatar, .initials{
        vertical-align: middle;
      }
      .avatar{
        img{
          width: 2.8rem;
          height: 2.8rem;
        }
      }
      .initials{
        width: 2.8rem;
        height: 2.8rem;
        line-height: 2.8rem;
        font-size: 1.4rem;
      }
      i{
        vertical-align: middle;
        padding: .6rem;
        border-radius: 100%;
        font-size: 1.6rem;
        color: $light_font_white_color;
        background-color: $light_icon_orange;
      }
      &:first-child{
        @include border-radius(5px 5px 0 0);
      }
      &:last-child{
        border: none !important;
        @include border-radius(0 0 5px 5px);
      }
    }
  }
}



// TABLE GRID VIEW
.grid-switch{
  background-color: $light_btn_primary;
  height: 3.6rem;
  line-height: 3.6rem;
  display: inline-block;
  padding: 0 1.5rem;
  @include projectBoxShadow;
  @include projectBorderRadius2-5rem;
  i{
    vertical-align: middle;
    position: relative;
    top: -1px;
    &.icon-grid-rows{
      margin-right: 1rem;
    }
    &.active{
      color: $light_font_primary_hvr;
    }
  }
}

#table{
  .grid-table-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
    margin: 1rem 0 2rem 0;
    width: 98%;
    .grid-table-card{
      position: relative;
      background-color: $light_cards_primary_color;
      @include projectBorderRadius;
      @include projectBoxShadow;
      .grid-table-show-info-box{
        .grid-table-show-info-btn{
          position: absolute;
          top: 1rem;
          left: 1rem;
          i{
            font-size: 1.2rem;
          }
        }
        .grid-table-show-info{
          overflow: auto;
          background: rgba(0,0,0, .85);
          z-index: $z_index_overlay;
          padding: 2rem;
          @include fullscreen(absolute, 0, 0, 0, 0);
          @include projectBorderRadius;
          p{
            color: $light_font_white_color;
          }
        }
      }
    }
  }
}


// TABLE THEMES

#table{
  &.customTheme{
    .initials, .icon-rounded{
      background-color: $custom_primary_color !important;;
    }
    .icon-rounded{
      &:hover{
        background-color: $custom_primary_color_hvr !important;
      }
    }
    .clickable_link{
      &:hover{
        color: $custom_primary_color_hvr !important;;
      }
    }
    .table-container{
      .colored{
        color: $custom_primary_color !important;
      }
      .table-row{
        i{
          &.icon-info{
            &:hover{
              color: $custom_primary_color_hvr !important;
            }
          }
          &.icon-more2{
            &:hover{
              color: $custom_primary_color_hvr !important;
            }
          }
        }
      }
    }
  }
  &.businessTheme{
    .initials, .icon-rounded{
      background-color: $business_primary_color !important;;
    }
    .icon-rounded{
      &:hover{
        background-color: $business_primary_color_hvr !important;
      }
    }
    .clickable_link{
      &:hover{
        color: $business_primary_color_hvr !important;;
      }
    }
    .table-container{
      .colored{
        color: $business_primary_color !important;
      }
      .table-row{
        i{
          &.icon-info{
            &:hover{
              color: $business_primary_color_hvr !important;
            }
          }
          &.icon-more2{
            &:hover{
              color: $business_primary_color_hvr !important;
            }
          }
        }
      }
    }
  }
}

#table{
  &.customTheme{
    .table-more-dropdown{
      ul{
        li{
          &:hover{
            color: $custom_primary_color_hvr;
          }
        }
      }
    }
  }
  &.businessTheme{
    .table-more-dropdown{
      ul{
        li{
          &:hover{
            color: $business_primary_color_hvr;
          }
        }
      }
    }
  }
}

// TABLE RESPONSIVE

//@media only screen and (max-width: 1600px) {
//  #table{
//    .grid-table-grid{
//      grid-template-columns: repeat(4, 1fr);
//    }
//  }
//}
//
//@media only screen and (max-width: 1440px) {
//  #table{
//    .grid-table-grid{
//      grid-template-columns: repeat(3, 1fr);
//    }
//  }
//}
//
//@media only screen and (max-width: 640px) {
//  #table{
//    .grid-table-grid{
//      grid-template-columns: repeat(2, 1fr);
//    }
//  }
//}
//
//
@media only screen and (max-width: 768px) {
  #table{
    .grid-table-grid{
      grid-template-columns: repeat(10, 1fr);
    }
  }
}




@import "~assets/styles/colors";
@import "~assets/styles/mixins";
@import "~assets/styles/variables";
@import "~assets/styles/themes/projectMixins";

$primary_color_dark: #444754;
$primary_color_light: #ffffff;

$font_primary_dark: $primary_color_dark;
$font_primary_light: $primary_color_light;

$primary_active_color: #4294e6;
$secondary_active_color: #e64b8d;

$state_error_color: #E54D42;
$state_warning_color: #ffb648;
$state_info_color: #1E90FF;
$state_success_color: #1abc9c;

$table_border_color: darken($primary_color_light, 10%);
$table_bg_color: $primary_color_light;
$header_font_color: $primary_color_dark;
$table_font_color: $primary_color_dark;
$arrows_color: $primary_color_dark;
$arrows_color_active: $primary_active_color;
$table_row_hover: $light_secondary_bg;

.table{
  overflow-y: hidden;
  overflow-x: auto;
  tbody tr.emphasized td {
    font-weight: bold !important;
  }
}


.table_header--actions{
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.icon-balance{
  color: rgb(29, 191, 191);
}

.icon-clock{
  color: rgb(36, 124, 178);
}

.icon-layers{
  color: rgb(36, 39, 178);
}

.data_table--filterDropdown-inner{
  ul{
    padding: 1rem 2rem;
    overflow-y: auto;
    max-height: 450px;
    li{
      margin-top: 1rem;
      &:first-child{
        margin-top: 0;
      }
      .dropdown{
        margin-top: .5rem;
      }
    }
  }
  .filterDropdown_inner_buttons{
    background-color: #eef3f8;
    div{
      width: 50%;
      display: inline-block;
      text-align: center;
      height: 4rem;
      line-height: 4rem;
      border-top: 1px solid $table_border_color;
      font-size: 1.2rem;
      &:first-child{
        border-right: 1px solid $table_border_color;
      }
    }
  }

}


table{
  width: 100%;
  border-spacing: 0;
  //background-color: $table_bg_color;

  th, td {
    line-height: 1.4rem;
    &.table_special{
      &:last-child{
        display: table-cell;
      }
    }
    &:last-child{
      //display: flex;
      display: table-cell;
      //justify-content: space-between;
      //align-items: center;
      div{
        line-height: 3rem;
      }
      .table-last-options{
        float: right;
        line-height: 3rem;
      }
    }
  }

  thead{
    tr{
      th {
        background-color: $light_primary_menu_bg;
        height: 35px;
        color: $light_font_white_color;
        font-size: 1.2rem;
        padding: 0 2rem;
        text-align: left;
        .thead_option--box{
          position: relative;
          float: right;
        }
        .thead_option--dropdown{
          position: absolute;
          top: calc(100% - 5px);
          right: 0;
          background-color: $light_font_white_color;
          border: 1px solid darken($light_font_white_color, 10%);
          z-index: 22;
          @include border-radius(5px);
          ul{
            padding: 1rem 2rem;
            li{
              a{
                white-space: nowrap;
                float: left;
                font-weight: normal;
                i{
                  color: $light_primary_menu_bg;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        &:last-child{
          display: table-cell;
        }
        &:first-child{
          @include border-radius(5px 0 0 0);
        }
        &:last-child{
          @include border-radius(0 5px 0 0);
        }
        i{
          color: $light_font_white_color;
          cursor: pointer;
          font-size: 1.2rem;
          @include transition(0.2s linear);
          &.active{
            color: $light_btn_blue;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:hover, &.active{
        td{
          &:first-child{
            border-left: 5px solid $light_btn_blue;
          }
          //background-color: $table_row_hover
          //border: 1px solid blue;
        };
      }
      &:last-child{
        td{
          &:first-child {
            @include border-radius(0 0 0 5px);
          }
          &:last-child {
            @include border-radius(0 0 5px 0);
          }
        }
      }
      td {
        &:first-child{
          border-left: 5px solid $light_border_primary_color;
        }
        border-top: 1px solid $light_border_primary_color;
        background-color: $table_bg_color;
        padding: 0 2rem;
        height: 40px;
        font-size: 1.2rem;
        @include fontRegular;
      }
      &.action_row {
        &:hover{
          border-color: #D6E0EA;
        }
      }
    }
  }


  //thead{
  //  th{
  //    &:last-child{
  //      display: table-cell;
  //    }
  //  }
  //}
  //th, td {
  //  font-size: 1.2rem;
  //  text-align: left;
  //  padding: 0;
  //  margin: 0;
  //  border-top: 1px solid $table_border_color;
  //  @include fontRegular;
  //  &.table_special{
  //    &:last-child{
  //      display: table-cell;
  //    }
  //  }
  //  &:first-child{
  //    padding-left: 2rem;
  //  }
  //  &:last-child{
  //    display: flex;
  //    justify-content: space-between;
  //    padding-right: 2rem;
  //  }
  //}
  //td{
  //  color: $table_font_color;
  //  height: 3.5rem;
  //  line-height: 3.5rem;
  //}
  //th{
  //  color: $header_font_color;
  //  height: 3rem;
  //  padding: .5rem 0;
  //  i{
  //    color: $arrows_color;
  //    cursor: pointer;
  //    font-size: 1.2rem;
  //    @include transition(0.2s linear);
  //    &.active{
  //      color: $arrows_color_active;
  //    }
  //  }
  //}
  //thead{
  //  .table_filter--name{
  //    @include fontSemiBold;
  //  }
  //  tr{
  //    th{
  //      background-color: $table_row_hover;
  //      &:first-child{
  //        padding-left: 2rem;
  //      }
  //      .table_filter--arrows{
  //        padding-right: 1rem;
  //      }
  //      &:last-child{
  //        padding-right: 2rem;
  //        .table_filter--arrows{
  //          padding-right: 0;
  //        }
  //      }
  //    }
  //  }
  //}
  //tbody{
  //  tr{
  //    &:hover, &.active{
  //      background-color: $table_row_hover;
  //    }
  //    td{
  //      padding: .5rem 0;
  //      &:first-child{
  //        padding-left: 2rem;
  //      }
  //      &:last-child{
  //        padding-right: 2rem;
  //      }
  //    }
  //  }
  //}


}

$table_limit_text_color: $primary_color_dark;

.table_limit_pagination{
  height: 3.5rem;
  line-height: 3.5rem;
  .display_flex{
    @media only screen and (max-width: 468px) {
      flex-direction: column;
    }
  }
}

.table_limit{
  span{
    color: $table_limit_text_color;
  }
}

$table_pagination_box_border_color: darken($primary_color_light, 10%);
$table_pagination_box_color: $primary_color_light;
$table_pagination_box_color_hover: darken($primary_color_light, 10%);
$table_pagination_box_color_active: $primary_active_color;
$table_pagination_box_color_active_font: $primary_color_light;
$table_pagination_arrows_color: $primary_color_dark;
$table_pagination_number_color: $primary_color_dark;

.table_pagination{

  .table_pagination_box{
    height: 3rem;
    line-height: 3rem;
    min-width: 3rem;
    text-align: center;
    background-color: $table_pagination_box_color;
    border: 1px solid $table_pagination_box_border_color;
    display: inline-block;
    margin: 0 .2rem;
    font-size: 1.2rem;
    @include border-radius(4px);
    &.active{
      background-color: $table_pagination_box_color_active;
      color: $table_pagination_box_color_active_font;
    }
    &:hover{
      background-color: $table_pagination_box_color_hover;
      color: $table_pagination_number_color;
    }
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    input{
      height: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      top: -1px;
      width: 100%;
      text-align: center;
      border: none;
      font-size: 1.2rem;
      @include border-radius(4px);
      &:hover{
        background-color: transparent;
      }
    }
  }

  .table_pagination--input{
    max-width: 8rem;
  }

  .table_pagination--prev, .table_pagination--next{
    color: $table_pagination_arrows_color;
    i{
      font-size: 1rem;
    }
  }

  .table_pagination--number{
    color: $table_pagination_number_color;
  }

}


// Display Helpers
.display--inlineBlock{
  display: inline-block !important;
}

.display_flex{
  display: flex;
}

.display_flex--justifyContent-spaceBetween{
  justify-content: space-between;
}

.display_flex--justifyItems-center{
  justify-items: center;
}

.display_flex--justifyContent-center{
  justify-content: center;
}

.display_flex--alignItems-center{
  align-items: center;
}

// Margin Helpers

.margin_reset--all{
  margin: 0 !important;
}

.margin_reset--top{
  margin-top: 0 !important;
}

.margin_reset--right{
  margin-right: 0 !important;
}

.margin_reset--bottom{
  margin-bottom: 0 !important;
}

.margin_reset--left{
  margin-left: 0 !important;
}

.margin_add--top-0-half{
  margin-top: .5rem !important;;
}

.margin_add--top-1{
  margin-top: 1rem !important;;
}

.margin_add--top-2{
  margin-top: 2rem !important;;
}

.margin_add--top-3{
  margin-top: 3rem !important;;
}

.margin_add--top-4{
  margin-top: 4rem !important;
}

.margin_add--right-1{
  margin-right: 1rem !important;;
}

.margin_add--right-2{
  margin-right: 2rem !important;;
}

.margin_add--right-3{
  margin-right: 3rem !important;;
}

.margin_add--right-4{
  margin-right: 4rem !important;;
}

.margin_add--bottom-1{
  margin-bottom: 1rem !important;;
}

.margin_add--bottom-2{
  margin-bottom: 2rem !important;;
}

.margin_add--bottom-3{
  margin-bottom: 3rem !important;;
}

.margin_add--bottom-4{
  margin-bottom: 4rem !important;;
}

.margin_add--left-1{
  margin-left: 1rem !important;;
}

.margin_add--left-2{
  margin-left: 2rem !important;;
}

.margin_add--left-3{
  margin-left: 3rem !important;;
}

.margin_add--left-4{
  margin-left: 4rem !important;;
}


// Click Helpers

.click{
  cursor: pointer !important;
}

.click_link{
  @include transition(0.2s linear !important);
}

.click_link--color-important{
  color: $primary_active_color;
  &:hover{
    //color: lighten($font_primary_dark, 40%) !important;
    color: darken($primary_active_color, 40%) !important;
  }
}

.click_link--color-action-normal{
  color: $primary_active_color;
  &:hover{
    color: darken($primary_active_color, 40%) !important;
  }
}

.click_link--color-action-warning{
  color: $secondary_active_color;
  &:hover{
    color: darken($secondary_active_color, 40%) !important;
  }
}

.click_link--color-dark{
  color: $font_primary_dark;
  &:hover{
    //color: lighten($font_primary_dark, 40%) !important;
    color: $primary_active_color !important;
  }
}

.click_link--color-light-hover{
  &:hover{
    color: $font_primary_light !important;
  }
}

.click_link--color-light{
  color: $font_primary_light;
  &:hover{
    //color: darken($font_primary_light, 10%) !important;
    color: $primary_active_color !important;
  }
}

.click--disabled{
  pointer-events: none !important;
}

.click--not-allowed{
  cursor: not-allowed !important;
}

// Position Helpers
.position--relative{
  position: relative !important;
}

// Font Helpers

.font--thin{
  @include fontThin;
}

.font--extraLight{
  @include fontExtraLight;
}

.font--light{
  @include fontLight;
}

.font--regular{
  @include fontRegular;
}

.font--medium{
  @include fontMedium;
}

.font--semiBold{
  @include fontSemiBold;
}

.font--bold{
  @include fontBold;
}

.font--extraBold{
  @include fontExtraBold;
}

.font--black{
  @include fontBlack;
}

.font--colored{
  color: $primary_active_color;
}

.current {
  position: relative;
  display: inline-block;
  margin-left: 1rem;
  i{
    font-size: 7px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    left: 3px;
  }
  .limitDropdown{
    position: absolute;
    top: -13rem;
    left: 5.5rem;
    background: $light_btn_white;
    width: auto;
    z-index: 2;
    @include projectBorderRadius;
    @include projectBoxShadow;
    ul{
      li{
        padding: 1rem 2rem;
        margin: 0;
        line-height: 1rem;
        cursor: pointer;
        &.active{
          color: $light_font_primary_hvr;
        }
        &:hover{
          color: $light_font_primary_hvr;
        }
      }
    }
  }
  &:before{
    content: '|';
    padding-right: 1rem;
  }
}

/* =========================
 ==== DATA TABLE TOTALI ====
 =========================*/


tfoot{
  background-color: #BF5783;
  tr {
    td {
      color: #ffffff;
      font-weight: 700;
      padding: 1rem;
      &:last-child{
        text-align: right;
        padding-right: 7.6%;
      }
    }
  }
}
