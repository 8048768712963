.modal-overlay{
  background: rgba(0,0,0, .95);
  z-index: 999;
  @include fullscreen(fixed, 0, 0, 0, 0);
}

.modal-container{
  display: flex;
  flex-direction: row;
  height:100vh;
  overflow: auto;
  padding: 2rem;
  .modal-content{
    margin: auto;
    height: auto;
    width: 100vw;
    max-width: 1200px;
    @include projectBoxShadow;
    @include projectBorderRadius;
  }
}
