.v-align{
  vertical-align: middle;
}

.initials-box{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
}

.initials-more{
  display: inline-block;
  margin-right: -10px;
}

.initials{
  width: 3.4rem;
  height: 3.4rem;
  line-height: 3.4rem;
  text-align: center;
  background-color: $light_btn_hvr;
  color: $light_font_white_color;
  font-size: 1.6rem;
  text-transform: uppercase;
  @include projectBorderRadiusRound;
  &.table-grid{
    width: 6.4rem;
    height: 6.4rem;
    line-height: 6.4rem;
    font-size: 2.6rem;
  }
  &.multiple{
    display: inline-block;
    border: 1px solid $light_font_white_color;
    span{
      position: inherit;
    }
  }
  span{
    position: relative;
    left: 1px;
    //Test Initials Align center
    display: inline-block;
    width: 100%;
    position: relative;
    left: 0px;
  }
}

.label{
  position: relative;
  display: inline;
  color: $light_font_white_color;
  padding: 0.15rem 0.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  height: 2rem;
  vertical-align: middle;
  border-radius: 0.3rem;
  background-color: $light_icon_silver;
}

.icon-rounded{
  width: 3.4rem;
  height: 3.4rem;
  line-height: 3.4rem;
  text-align: center;
  color: $light_font_white_color;
  @include projectBorderRadiusRound;
  &.bigger{
    width: 6.4rem;
    height: 6.4rem;
    line-height: 6.4rem;
    i{
      font-size: 2.5rem;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
  &.bigger-fake-logo{
    width: 10rem;
    height: 10rem;
    line-height: 10rem;
    margin: auto;
    i{
      font-size: 3rem;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
  &.mail{
    display: inline-block;
    margin-right: .5rem;
    background-color: $light_font_primary_hvr;
    @include projectTransition;
    &:hover{
      background-color: $light_font_secondary_hvr;
    }
  }
}

.avatar{
  &.smaller{
    img{
      border: 1px solid $light_border_primary_color;
      margin-bottom: 4px;
    }
  }
  &.bigger{
    img {
      width: 6.4rem;
      height: 6.4rem;
    }
  }
  &.bigger-x2{
    img {
      width: 8rem;
      height: 8rem;
    }
  }
  img{
    width: 3.4rem;
    height: 3.4rem;
    vertical-align: middle;
    display: inline-block;
    background-color: #dbe4ed;
    @include projectBorderRadiusRound;
  }
}

i{
  &.icon-more2{
    color: $light_icon_silver;
  }
}
